import React from "react";
import { FormControl, Grid, InputLabel, TextField } from "@mui/material";
import { MyButton } from "./LeftSideStyle";
import uploadimg from "../../assets/uploadImage.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import { SubmitButton } from "../Layout/Submit";
import { useDispatch, useSelector } from "react-redux";
import { registerInstaller } from "../../Redux/Slices/RegisterSlice";
import Lottie from "lottie-react";
import loaderlottie from "../../Components/Loader/loader.json";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker/';
const FirstForm = ({ handleNext }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    name: yup.string("Enter your Location").required("Name is required"),
    // address: yup.string("Enter your address").required("address is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),

    password: yup
      .string("Enter your password")
      .required("password is required"),

    // date_of_origination: yup
    //   .date("Enter your date_of_origination")
    // .required("Start time is required"),

    // company_name: yup
    //   .string("Enter your Company Name")
    //   .required("Company Name is required"),
    // phone: yup.string("Enter your phone").required("phone  is required"),

    // business_fax: yup
    //   .string("Enter your Business Fax")
    //   .required("Business Fax is required"),

    // logo_url: yup.mixed().required("File is required"),
  });
  const loader = useSelector((state) => state.register.status);
  const [date, setDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [file, setFile] = React.useState();
  const senDate = {
    date: new Date(date).toISOString().split("T")[0],
  };
  console.log("Send Date", senDate);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      company_name: "",
      address: "",
      date_of_origination: new Date(date).toISOString().split("T")[0],
      phone: "",
      logo_url: "",
      business_fax: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      const form = new FormData();
      form.append("name", formik.values.name);
      form.append("email", formik.values.email);
      form.append("password", formik.values.password);
      form.append("phone", formik.values.phone);
      form.append("company_name", formik.values.company_name);
      form.append("logo_url", file);
      form.append("address", formik.values.address);
      form.append("business_fax", formik.values.business_fax);
      form.append("date_of_origination", senDate.date);

      await dispatch(registerInstaller(form));
      // await dispatch(CreateContainer({ ...values, ...sendDate }));
      // console.log("status", status);
      // await dispatch(Get_Containers(data));
      // handleClose();
    },
  });
  console.log("loader", loader);
  React.useEffect(() => {
    if (loader === "success") {
      Swal.fire({
        // position: 'top-end',
        icon: "success",
        title: "You will be Directed to login Page in 4 second",
        showConfirmButton: false,
        timer: 4000,
      });
      setTimeout(() => {
        navigate("//www.technician.boxbyld.tech/login");
      }, 4000);

      console.log("loader", loader);
    }
  }, [loader]);

  return (
    <>
      {loader !== "loading" && (
        <>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                columns={12}
                // justifyContent="center"
                // alignItems="center"
                spacing={2}
                style={{ padding: "1rem 3rem" }}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <InputLabel htmlFor="component-simple">Name</InputLabel>
                  <TextField
                    size="small"
                    fullWidth
                    id="input-with-icon-textfield"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <InputLabel htmlFor="component-simple">Email</InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    id="input-with-icon-textfield"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <InputLabel htmlFor="component-simple">Password</InputLabel>
                  <TextField
                    size="small"
                    fullWidth
                    id="input-with-icon-textfield"
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </Grid>
                {/* <Grid item xs={12} md={12} lg={6}>
            <InputLabel htmlFor="component-simple">Confirm Password</InputLabel>
            <TextField
              fullWidth
              size="small"
              id="input-with-icon-textfield"
              name="project_name"
            />
          </Grid> */}
                <Grid item xs={12} ml={1}>
                  <div className="header">
                    <h3>Company Info</h3>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <InputLabel htmlFor="component-simple">
                    Company Name
                  </InputLabel>
                  <TextField
                    size="small"
                    fullWidth
                    id="input-with-icon-textfield"
                    name="company_name"
                    value={formik.values.company_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.company_name &&
                      Boolean(formik.errors.company_name)
                    }
                    helperText={
                      formik.touched.company_name && formik.errors.company_name
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <InputLabel htmlFor="component-simple">Phone</InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    id="input-with-icon-textfield"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <InputLabel htmlFor="component-simple">
                    Business Fax
                  </InputLabel>
                  <TextField
                    size="small"
                    fullWidth
                    id="input-with-icon-textfield"
                    name="business_fax"
                    value={formik.values.business_fax}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.business_fax &&
                      Boolean(formik.errors.business_fax)
                    }
                    helperText={
                      formik.touched.business_fax && formik.errors.business_fax
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <InputLabel htmlFor="component-simple">Address</InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                    id="input-with-icon-textfield"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={6}>
            <InputLabel htmlFor="component-simple">Date</InputLabel>
            <TextField
              size="small"
              fullWidth
              id="input-with-icon-textfield"
              name="date"
              type="date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
          </Grid> */}
                {/* <Grid container item columns={12}>
            <Grid item lg={6} md={6}>
              <div className="inputFile">
                <img src={uploadimg}></img>
                <MyButton variant="contained" component="label">
                  Upload
                  <input
                    type="file"
                    hidden
                    name="logo_url"
                    // value={formik.values.logo_url}
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                    }}
                    // onChange={formik.handleChange}
                  />
                </MyButton>
                <span>
                  {file ? (
                    <span></span>
                  ) : (
                    <p
                      style={{
                        color: "red",
                        fontSize: "10",
                        textAlign: "center",
                      }}
                    >
                      File is required
                    </p>
                  )}
                </span>
              </div>
            </Grid>
          </Grid> */}
                <Grid
                  container
                  item
                  columns={12}
                  style={{ padding: "2rem 8rem" }}
                >
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <SubmitButton
                      style={{ background: "white", color: "#7f56d9" }}
                    >
                      Cancel
                    </SubmitButton>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <SubmitButton type="submit">Submit</SubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </>
      )}

      {loader === "loading" && (
        <>
          <Lottie loop={true} animationData={loaderlottie} />
        </>
      )}
    </>
  );
};

export default FirstForm;
