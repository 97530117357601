import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { login } from "../../Api";
// Get the Data From Local Storage
try {
  var user = JSON.parse(localStorage.getItem("installer"));
} catch (e) {}
// Header for Apis
const headers = {
  Accept: "application/json",
  XMLHttpRequest: "XMLHttpRequest",
  Authorization: `Bearer ${user?.token}`,
};
/* Login Function */
export const authentication = createAsyncThunk(
  "auth/getAuth",
  async (data, thunkAPI) => {
    try {
      return await axios({
        method: "POST",
        baseURL: `${login}`,
        headers: headers,
        data: data,
      }).then((res) => {
        console.log("Response ", res.data.data);
        if (res) {
          localStorage.setItem("installer", JSON.stringify(res.data.data));
          toast.success(`welcome ${res.data.data.name}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/* Logout Function */
export const logout = createAsyncThunk("auth/logout", async () => {
  await localStorage.removeItem("installer");
});

/* Authentication Slice */
const authSlice = createSlice({
  name: "auth",
  initialState: {
    data: user ? user : null,
    token: "",
    isAuth: false,
    status: "",
    error: "",
    userData: "",
  },
  reducers: {},
  extraReducers: {
    [authentication.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [authentication.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.data = payload;
      state.isAuth = true;
    },
    [authentication.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.isAuth = false;
      state.error = payload;
    },
    [logout.fulfilled]: (state, { payload }) => {
      state.isAuth = false;
      state.data = null;
    },
  },
});

export default authSlice.reducer;
