import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { register } from "../../Api";
import { toast } from "react-toastify";

const headers = {
  Accept: "application/json",
};
export const registerInstaller = createAsyncThunk(
  "installer/register",
  async (data, thunkAPI) => {
    try {
      return await axios({
        method: "POST",
        baseURL: `${register}`,
        headers: headers,
        data: data,
      }).then((res) => {
        console.log("Response ", res.data.data);

        toast.success("Installer has been registered", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message      ;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const regSlice = createSlice({
  name: "registerInstaller",
  initialState: {
    installerData: "",
    status:""
  },
  reducers: {},
  extraReducers: {
    [registerInstaller.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [registerInstaller.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.washing_types = payload.data;
    },
    [registerInstaller.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});
export default regSlice.reducer;
