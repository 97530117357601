import styled from "styled-components";

export const AdderStyle = styled.div`
  button {
    text-align: center;
    width: 80%;
    cursor: pointer;
    color: white;
    background-color: #7f56d9;
    width: 90%;
    text-align: center;
    border: 1px solid #7f56d9;
    border-radius: 8px;
    padding: 1rem 0;
    margin-top: 50px;
    font-size: 20px;
  }
`;
