import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  clear_working_days,
  GetWorkignDays,
  license,
  working_days,
} from "../../Api";
import { toast } from "react-toastify";

try {
  var user = JSON.parse(localStorage.getItem("installer"));
} catch (e) {}

const headers = {
  Accept: "application/json",
  Authorization: `Bearer ${user?.token}`,
};

export const AddWorkingDays = createAsyncThunk(
  "add/workingDays",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${working_days}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success("Working Days has been Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const ClearWorkingDays = createAsyncThunk(
  "clear/workingDays",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "DELETE",
        baseURL: `${clear_working_days}/${data}`,
        headers: headers,
      }).then((res) => {
        toast.success("Working Days has been Cleared", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetWorkignDaysInstaler = createAsyncThunk(
  "GET/workingDays",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${GetWorkignDays}`,
        headers: headers,
        // data: data,
      }).then((res) => {
        // toast.success("Working Days has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const WorkignDays = createSlice({
  name: "workignDays",
  initialState: {
    working_days: "",
    status: "",
    getWorkingDays: "",
  },
  reducers: {
    workingDaysReset: (state) => {
      state.status = "";
    },
  },
  extraReducers: {
    [AddWorkingDays.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [AddWorkingDays.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.working_days = payload.data;
    },
    [AddWorkingDays.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [ClearWorkingDays.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [ClearWorkingDays.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.working_days = payload.data;
    },
    [ClearWorkingDays.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [GetWorkignDaysInstaler.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetWorkignDaysInstaler.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.getWorkingDays = payload.data;
    },
    [GetWorkignDaysInstaler.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});
export const { workingDaysReset } = WorkignDays.actions;
export default WorkignDays.reducer;
