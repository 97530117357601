import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  MyButton,
  AddMore,
  Style,
  Div,
  LicenseDiv,
  Result,
} from "./LeftSideStyle";
import uploadimg from "../../assets/uploadImage.svg";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { SubmitButton } from "../Layout/Submit";
import { useDispatch, useSelector } from "react-redux";
import { registerInstaller } from "../../Redux/Slices/RegisterSlice";
import { AddLicense, GetLicense } from "../../Redux/Slices/AddLicense";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { license_types } from "./DropDowns";
import { GetStates } from "../../Redux/Slices/StatesSlice";
import Swal from "sweetalert2";
import { GetWorkignDaysInstaler } from "../../Redux/Slices/AddWorkingDays";
import { GetUserInfo } from "../../Redux/Slices/AuthSlice";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import Lottie from "lottie-react";
import loaderlottie from "../../Components/Loader/loader.json";
import axios from "axios";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
const SecondForm = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(GetLicense());
  }, []);
  const [timeZone, setTimeZone] = React.useState();

  console.log("timeZone", timeZone);
  const allLicenses = useSelector((state) => state.license.allLicenses) || [];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    let ignore = false;
    if (!ignore) {
      dispatch(GetStates());
    }
    return () => {
      ignore = true;
    };
  }, []);
  const { states } = useSelector((state) => state?.states);
  const allStates = states?.states || [];
  const loader = useSelector((state) => state.license.status);
  const add_liecnse_status = useSelector(
    (state) => state.license.add_liecnse_status
  );

  const validationSchema = yup.object().shape({
    license_number: yup
      .string("Enter your License number")
      .required("License number is required"),
    license_type: yup
      .string("Select your License type")
      .required("License type is required"),
    license_holder: yup
      .string("Select your License type")
      .required("License holder is required"),
  });
  const [date, setDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const [file, setFile] = React.useState("");
  const [newFiles, setNewFiles] = React.useState({
    drivers_license: "",
    workers_comp_insurance: "",
    liability_insurance: "",
  });
  const senDate = {
    date: new Date(date).toISOString().split("T")[0],
  };
  const InstallerData = JSON.parse(localStorage.getItem("installer"));
  const [licenses, setLicenses] = React.useState([]);
  console.log("licenses", licenses);
  const formik = useFormik({
    initialValues: {
      user_id: InstallerData.id,
      license_number: "",
      license_type: "",
      state: "",
      license_expiration_date: new Date(date).toISOString().split("T")[0],
      license_holder: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (file !== "") {
        setLicenses((preValue) => [
          ...preValue,
          {
            license_number: formik?.values?.license_number,
            license_type: formik?.values?.license_type,
            license_holder: formik?.values?.license_holder,
            state_id: formik?.values?.state,
            license_expiration_date: formik?.values?.license_expiration_date,
            license_img: file,
            phone: InstallerData?.phone,
            state_abbr: state,
            lat: coordinates?.lat,
            lng: coordinates?.lng,
            full_address: full_address,
            dstOffset: timeZone?.dstOffset,
            rawOffset: timeZone?.rawOffset,
            timeZoneId: timeZone?.timeZoneId,
            timeZoneName: timeZone?.timeZoneName,
            drivers_license: newFiles?.drivers_license,
            workers_comp_insurance: newFiles?.workers_comp_insurance,
            liability_insurance: newFiles?.liability_insurance,
          },
        ]);
        formik.resetForm();
        setFile("");
        handleClose();
        setAddress("");
      } else {
      }
    },
  });
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4",
    libraries: ["places"],
  });

  console.log("Formik Formk Formik", formik);
  const handleSubmit = async () => {
    const form = new FormData();
    if (licenses.length > 0) {
      licenses.map((e, i) => {
        for (const property in e) {
          form.append(`data[${i}][${property}]`, e[property]);
        }
      });
      await dispatch(AddLicense(form));
      await dispatch(GetLicense());
      if (add_liecnse_status === "success") setLicenses([]);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Need to Add at least one license",
      }).then(() => {
        handleOpen();
      });
    }
  };
  console.log("license_types", license_types);
  const getType = (id) => {
    return license_types.find((e) => e.value === id);
  };
  const deleteLiecense = (i) => {
    const updatedlist = [...licenses];
    if (i > -1) {
      updatedlist.splice(i, 1);
    }
    setLicenses(updatedlist);
    console.log("updatedlist", updatedlist);
  };
  const fineState = (id) => {
    return allStates?.find((e) => e.id === id);
  };
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState();
  const [singleAdress, setSingleAddress] = React.useState();
  // console.log("Check Date", new Date().getMilliseconds);
  console.log("singleAdress", singleAdress);
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };
  const [full_address, setFullAddress] = React.useState("");
  const [zip_code, setPostalCode] = React.useState("");
  const [street, setStreetLoc] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [route, setRoute] = React.useState("");
  const [city, setCity] = React.useState("");
  console.log({ city, zip_code, street, country, route, state, full_address });
  // GeoCoding
  React.useEffect(() => {
    console.log("in useEffect");
    Geocode.setLocationType("ROOFTOP");
    Geocode.setApiKey("AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4");
    Geocode.fromLatLng(coordinates?.lat, coordinates?.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let street, city, state, country, postalCode, route;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "street_number":
                street = response.results[0].address_components[i].long_name;
                break;
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postalCode =
                  response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].short_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;

              case "route":
                route = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        // Set the Normal states

        setPostalCode(postalCode);
        setFullAddress(address);
        setStreetLoc(street);
        setCountry(country);
        setState(state);
        setRoute(route);
        setCity(city);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [coordinates]);
  React.useEffect(() => {
    axios({
      method: "get",
      url: `https://maps.googleapis.com/maps/api/timezone/json?location=${
        coordinates?.lat
      },${coordinates?.lng}&timestamp=${
        Date.now() / 1000
      }&key=AIzaSyCrr_bBNHxQ0lZKadmJYpqS2vOebczD1m4`,
    })
      .then(function (response) {
        setTimeZone(response.data);
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }, [coordinates]);
  return (
    <>
      {loader !== "loading" && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                Add License
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <form onSubmit={formik.handleSubmit}>
                  <Grid
                    container
                    columns={12}
                    spacing={2}
                    style={{ padding: "1rem 3rem" }}
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <InputLabel htmlFor="component-simple">
                        Licence number{" "}
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth
                        id="input-with-icon-textfield"
                        name="license_number"
                        value={formik.values.license_number}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.license_number &&
                          Boolean(formik.errors.license_number)
                        }
                        helperText={
                          formik.touched.license_number &&
                          formik.errors.license_number
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <InputLabel htmlFor="component-simple">
                        License holder{" "}
                      </InputLabel>
                      <TextField
                        size="small"
                        fullWidth
                        id="input-with-icon-textfield"
                        name="license_holder"
                        value={formik.values.license_holder}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.license_holder &&
                          Boolean(formik.errors.license_holder)
                        }
                        helperText={
                          formik.touched.license_holder &&
                          formik.errors.license_holder
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <InputLabel htmlFor="component-simple">
                        License Type
                      </InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        // labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formik.values.license_type}
                        name="license_type"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.license_type &&
                          Boolean(formik.errors.license_type)
                        }
                        helperText={
                          formik.touched.license_type &&
                          formik.errors.license_type
                        }
                      >
                        {license_types?.map((util) => (
                          <MenuItem key={util.value} value={util.value}>
                            {util.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <InputLabel htmlFor="component-simple">States</InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        // labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formik.values.state}
                        name="state"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                      >
                        {allStates?.map((state) => (
                          <MenuItem key={state.id} value={state.id}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <InputLabel htmlFor="component-simple">
                        License expiration date
                      </InputLabel>
                      <TextField
                        fullWidth
                        size="small"
                        id="input-with-icon-textfield"
                        name="license_expiration_date"
                        value={formik.values.license_expiration_date}
                        type="date"
                        onChange={formik.handleChange}
                        error={
                          formik.touched.license_expiration_date &&
                          Boolean(formik.errors.license_expiration_date)
                        }
                        helperText={
                          formik.touched.license_expiration_date &&
                          formik.errors.license_expiration_date
                        }
                      />
                    </Grid>

                    <Grid container item columns={12}>
                      <Grid item lg={6} md={6}>
                        <div
                          className="inputFile"
                          style={{ textAlign: "center" }}
                        >
                          <img src={uploadimg}></img>
                          <MyButton variant="contained" component="label">
                            Upload Liecnse
                            <input
                              type="file"
                              hidden
                              name="logo_url"
                              accept=".png, .jpg, .jpeg"
                              // value={formik.values.logo_url}
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                              }}
                              // onChange={formik.handleChange}
                            />
                          </MyButton>
                          <span>
                            {file ? (
                              <span></span>
                            ) : (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "10",
                                  textAlign: "center",
                                }}
                              >
                                File is required
                              </p>
                            )}
                          </span>
                        </div>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <div
                          className="inputFile"
                          style={{ textAlign: "center" }}
                        >
                          <img src={uploadimg}></img>
                          <MyButton variant="contained" component="label">
                            Upload drivers license
                            <input
                              type="file"
                              hidden
                              name="logo_url"
                              accept=".png, .jpg, .jpeg"
                              // value={formik.values.logo_url}
                              onChange={(e) => {
                                setNewFiles({
                                  ...newFiles,
                                  drivers_license: e.target.files[0],
                                });
                              }}
                              // onChange={formik.handleChange}
                            />
                          </MyButton>
                          <span>
                            {newFiles?.drivers_license ? (
                              <span></span>
                            ) : (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "10",
                                  textAlign: "center",
                                }}
                              >
                                File is required
                              </p>
                            )}
                          </span>
                        </div>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <div
                          className="inputFile"
                          style={{ textAlign: "center" }}
                        >
                          <img src={uploadimg}></img>
                          <MyButton variant="contained" component="label">
                            Upload workers comp insurance
                            <input
                              type="file"
                              hidden
                              name="logo_url"
                              accept=".png, .jpg, .jpeg"
                              // value={formik.values.logo_url}
                              onChange={(e) => {
                                setNewFiles({
                                  ...newFiles,
                                  workers_comp_insurance: e.target.files[0],
                                });
                              }}
                              // onChange={formik.handleChange}
                            />
                          </MyButton>
                          <span>
                            {newFiles?.workers_comp_insurance ? (
                              <span></span>
                            ) : (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "10",
                                  textAlign: "center",
                                }}
                              >
                                File is required
                              </p>
                            )}
                          </span>
                        </div>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <div
                          className="inputFile"
                          style={{ textAlign: "center" }}
                        >
                          <img src={uploadimg}></img>
                          <MyButton variant="contained" component="label">
                            Upload liability insurance
                            <input
                              type="file"
                              hidden
                              name="logo_url"
                              accept=".png, .jpg, .jpeg"
                              // value={formik.values.logo_url}
                              onChange={(e) => {
                                setNewFiles({
                                  ...newFiles,
                                  liability_insurance: e.target.files[0],
                                });
                              }}
                              // onChange={formik.handleChange}
                            />
                          </MyButton>
                          <span>
                            {newFiles?.liability_insurance ? (
                              <span></span>
                            ) : (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "10",
                                  textAlign: "center",
                                }}
                              >
                                File is required
                              </p>
                            )}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={18} sm={18} sx={{ mb: 2 }}>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <TextField
                              sx={{ mb: 3 }}
                              fullWidth
                              {...getInputProps({
                                placeholder: "Type Your address",
                              })}
                              required
                            ></TextField>

                            <Result>
                              {loading ? <div>...loading</div> : null}

                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                  textAlign: "center",
                                  fontSize: "13px",
                                };

                                return (
                                  <MenuItem
                                    style={{ border: "1px solid black" }}
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </MenuItem>
                                );
                              })}
                            </Result>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Grid>
                  </Grid>
                  <Grid xs={12}>
                    <MyButton
                      style={{ color: "white", width: "100%" }}
                      // onClick={(e) => {}}
                      type="submit"
                    >
                      Add License
                    </MyButton>
                  </Grid>
                </form>
              </Typography>
            </Box>
          </Modal>
          <Grid container columns={12} spacing={3} sx={{ padding: "0 0" }}>
            <Grid item xs={6}>
              {" "}
              <Grid item xs={12}>
                <AddMore
                  startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
                  onClick={handleOpen}
                >
                  Add License
                </AddMore>
              </Grid>
              <Grid item xs={12}>
                {licenses.map((e, i) => {
                  return (
                    <LicenseDiv key={i}>
                      <p>
                        {" "}
                        license number: <span>{e.license_number}</span>
                      </p>
                      <p>
                        {" "}
                        license state:{" "}
                        <span>{fineState(e.state_id)?.name}</span>
                      </p>
                      <p>
                        {" "}
                        license Type:{" "}
                        <span>{getType(e.license_type)?.name}</span>
                      </p>
                      {/* <p>
                        {" "}
                        license number: <span>{e.license_number}</span>
                      </p> */}
                      <p>
                        {" "}
                        license expiration date:{" "}
                        <span>
                          {new Date(
                            e.license_expiration_date
                          ).toLocaleDateString("en-US", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                        </span>
                      </p>
                      <div className="img">
                        {" "}
                        <Gallery>
                          <Item
                            original={URL?.createObjectURL(e?.license_img)}
                            thumbnail={URL?.createObjectURL(e?.license_img)}
                            // width="200"
                            // height="200"
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                src={e?.license_img}
                              />
                            )}
                          </Item>
                          <Item
                            original={URL?.createObjectURL(e?.license_img)}
                            thumbnail={URL?.createObjectURL(e?.license_img)}
                            width="500"
                            height="500"
                          >
                            {({ ref, open }) => (
                              <img
                                ref={ref}
                                onClick={open}
                                src={URL?.createObjectURL(e?.license_img)}
                              />
                            )}
                          </Item>
                        </Gallery>
                        {/* <img src={URL.createObjectURL(e.license_img)}></img> */}
                      </div>
                      <div className="deleteButton">
                        <button
                          onClick={() => {
                            deleteLiecense(i);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </LicenseDiv>
                  );
                })}
              </Grid>
              <Grid
                container
                item
                columns={12}
                style={{ padding: "2rem 8rem" }}
              >
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <SubmitButton
                    style={{ background: "white", color: "#7f56d9" }}
                  >
                    Cancel
                  </SubmitButton>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  <SubmitButton onClick={handleSubmit} type="button">
                    Submit
                  </SubmitButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {allLicenses?.business_licence?.map((e, i) => {
                return (
                  <LicenseDiv key={i}>
                    <p>
                      {" "}
                      license number: <span>{e.license_number}</span>
                    </p>
                    <p>
                      {" "}
                      license state: <span>{e.state}</span>
                    </p>
                    <p>
                      {" "}
                      license Type:{" "}
                      <span>{getType(Number(e.license_type))?.name}</span>
                    </p>
                    {/* <p>
                      {" "}
                      license number: <span>{e.license_number}</span>
                    </p> */}
                    <p>
                      {" "}
                      license expiration date:{" "}
                      <span>
                        {new Date(e.license_expiration_date).toLocaleDateString(
                          "en-US",
                          { day: "2-digit", month: "2-digit", year: "numeric" }
                        )}
                      </span>
                    </p>
                    <div className="img">
                      {" "}
                      <Gallery>
                        <Item
                          original={e.file?.license_img}
                          thumbnail={e.file?.license_img}
                        >
                          {({ ref, open }) => (
                            <img
                              ref={ref}
                              onClick={open}
                              src={e.file?.license_img}
                            />
                          )}
                        </Item>
                        <Item
                          original={e.file?.license_img}
                          thumbnail={e.file?.license_img}
                          width="500"
                          height="500"
                        >
                          {({ ref, open }) => (
                            <img
                              ref={ref}
                              onClick={open}
                              src={e.file?.license_img}
                            />
                          )}
                        </Item>
                      </Gallery>
                    </div>
                    {/* <img src={e.file}></img> */}
                  </LicenseDiv>
                );
              })}
            </Grid>
          </Grid>
        </>
      )}

      {loader === "loading" && (
        <>
          <Lottie loop={true} animationData={loaderlottie} />
        </>
      )}
    </>
  );
};

export default SecondForm;
