import { Grid } from "@mui/material";
import React from "react";
import LeftSide from "../../Components/Form/LeftSide";

const Register = () => {
  return (
    <>
      <Grid container columns={12}>
        <Grid item xs={12} lg={12} md={12}>
            <LeftSide></LeftSide>
        </Grid>
      </Grid>
    </>
  );
};

export default Register;
