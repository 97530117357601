export const price_type = [
  { name: "labor and material", value: "labor and material" },
  { name: "labor only", value: "labor only" },
  { name: "material only", value: "material only" },
];
export const is_adder = [
  { name: "adder", value: "adder" },
  { name: "no adder", value: "no adder" },
  { name: "no can do", value: "no can do" },
];
export const availability = [
  { name: "true", value: 1 },
  { name: "false", value: 0 },
];
export const unit_array = [
  { name: "per watt", value: "per watt" },
  { name: "per panel", value: "per panel" },
  { name: "amount", value: "amount" },
  { name: "per feet", value: "per feet" },
];
