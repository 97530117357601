import React from "react";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { SubmitButton } from "../Layout/Submit";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { MyButton } from "../Form/LeftSideStyle";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  GetFormData,
  GetsiteSurvey,
  siteSurvey,
} from "../../Redux/Slices/SiteServy";
import { useLocation, useParams } from "react-router-dom";
import { Msp, ServieType } from "./staticArrays";
const SiteSurvey = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const location = useLocation();
  const ids = location?.pathname.split("/home/jobs-orders/site-survey/") || [];
  const ticket_id = ids[1]?.split("/") || [];
  const worker_id = ids[1]?.split("/") || [];
  React.useEffect(() => {
    dispatch(GetsiteSurvey(worker_id[1]));
    dispatch(GetFormData());
  }, []);
  const singleServey = useSelector((state) => state.sitServey.SingleSiteSurvey);
  const formData = useSelector((state) => state.sitServey.formData);
  console.log("singleServeysingleServey", singleServey);
  console.log("formDataformData", formData);
  const validationSchema = yup.object().shape({
    // layout_sketch: yup.mixed().required("You need to provide a file"),
    // .test("fileSize", "The file is too large", (value, context) => {
    //   return value && value[0] && value[0].size <= 200000;
    // })
    // .test("type", "We only support jpeg", function (value) {
    //   return value && value[0] && value[0].type === "image/jpeg";
    // }),
    // main_panel: yup.mixed().required("You need to provide a file"),
  });

  const [file, setFile] = React.useState("");

  const formik = useFormik({
    initialValues: {
      layout_sketch: file,
    },
    // validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log("Values", values);
    },
  });
  const [sketchFiles, setSketchFiles] = React.useState([]);
  const [otherPhotos, setOtherphotos] = React.useState([]);
  const [panelSub, setPanelSub] = React.useState([]);
  const [widePhotos, setWidePhotos] = React.useState([]);
  const [closeUpPhotos, setcloseUpPhotos] = React.useState([]);
  const user = JSON.parse(localStorage?.getItem("installer"));
  const MAX_COUNT = 50;
  const [fileLimit, setFileLimit] = React.useState(false);
  // sketchFiles
  const handleUploadFiles = (files) => {
    const uploaded = [...sketchFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setSketchFiles(uploaded);
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  // otherPhotos
  const handleOtherPhotosFiles = (files) => {
    const uploaded = [...otherPhotos];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setOtherphotos(uploaded);
  };
  const handleFileEventOtherPhotos = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleOtherPhotosFiles(chosenFiles);
  };
  // panelSub
  const handlePanelSubFiles = (files) => {
    const uploaded = [...panelSub];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setPanelSub(uploaded);
  };
  const handleFileEventPanelSub = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handlePanelSubFiles(chosenFiles);
  };
  // widePhotos
  const handlewidePhotosFiles = (files) => {
    const uploaded = [...widePhotos];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setWidePhotos(uploaded);
  };
  const handleFileEventwidePhotos = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handlewidePhotosFiles(chosenFiles);
  };
  // closeUpPhotos
  const handlecloseUpPhotosFiles = (files) => {
    const uploaded = [...closeUpPhotos];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setcloseUpPhotos(uploaded);
  };
  const handleFileEventcloseUpPhotos = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handlecloseUpPhotosFiles(chosenFiles);
  };
  console.log("IDDDDDDD", id);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("ticket_id", ticket_id[0]);
    formData.append("work_order_id", worker_id[1]);
    formData.append("installer_id", user?.id);
    formData.append("roof_type", data?.roof_type);
    formData.append("roof_tilt", data?.roof_tilt);
    formData.append("rafter_size", data?.rafter_size);
    formData.append("rafter_spacing", data?.rafter_spacing);
    formData.append("mcb_rating", data?.mcb_rating);
    formData.append("meter_number", data?.meter_number);
    formData.append("msp_voltage", data?.msp_voltage);
    formData.append("service_type", data?.service_type);
    sketchFiles.map((e, i) => {
      formData.append(`layout_sketch[${i}]`, e);
    });
    closeUpPhotos.map((e, i) => {
      formData.append(`close_up_photos[${i}]`, e);
    });
    widePhotos.map((e, i) => {
      formData.append(`wide_photos[${i}]`, e);
    });
    panelSub.map((e, i) => {
      formData.append(`panel_sub_panel_photos[${i}]`, e);
    });
    otherPhotos.map((e, i) => {
      formData.append(`other_photos[${i}]`, e);
    });
    dispatch(siteSurvey(formData));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography component="span">
        <Grid container columns={12} spacing={3} component="span">
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Roof Type</InputLabel>
            <Select
              fullWidth
              size="small"
              name="roof_type"
              id="demo-simple-select"
              {...register("roof_type")}
            >
              {formData.roof_existing_materials?.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Roof Tilt</InputLabel>
            <TextField
              size="small"
              fullWidth
              id="input-with-icon-textfield"
              name="roof_tilt"
              type="number"
              // value={formik.values.roof_tilt}
              // onChange={formik.handleChange}
              // error={
              //   formik.touched.roof_tilt && Boolean(formik.errors.roof_tilt)
              // }
              // helperText={formik.touched.roof_tilt && formik.errors.roof_tilt}
              {...register("roof_tilt")}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Reafter size</InputLabel>
            <TextField
              size="small"
              fullWidth
              id="input-with-icon-textfield"
              name="rafter_size"
              // value={formik.values.rafter_size}
              // onChange={formik.handleChange}
              // error={
              //   formik.touched.rafter_size && Boolean(formik.errors.rafter_size)
              // }
              // helperText={
              //   formik.touched.rafter_size && formik.errors.rafter_size
              // }
              {...register("rafter_size")}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Reafter spacing</InputLabel>
            <TextField
              size="small"
              fullWidth
              id="input-with-icon-textfield"
              name="rafter_spacing"
              type="number"
              // value={formik.values.rafter_spacing}
              // onChange={formik.handleChange}
              // error={
              //   formik.touched.rafter_spacing &&
              //   Boolean(formik.errors.rafter_spacing)
              // }
              // helperText={
              //   formik.touched.rafter_spacing && formik.errors.rafter_spacing
              // }
              {...register("rafter_spacing")}
            />
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">MCB Rating</InputLabel>
            <TextField
              size="small"
              fullWidth
              id="input-with-icon-textfield"
              name="mcb_rating"
              type="number"
              // value={formik.values.mcb_rating}
              // onChange={formik.handleChange}
              // error={
              //   formik.touched.mcb_rating && Boolean(formik.errors.mcb_rating)
              // }
              // helperText={formik.touched.mcb_rating && formik.errors.mcb_rating}
              {...register("mcb_rating")}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Buss Rating</InputLabel>
            <TextField
              size="small"
              fullWidth
              id="input-with-icon-textfield"
              name="bass_rating"
              type="number"
              // value={formik.values.bass_rating}
              // onChange={formik.handleChange}
              // error={
              //   formik.touched.bass_rating && Boolean(formik.errors.bass_rating)
              // }
              // helperText={
              //   formik.touched.bass_rating && formik.errors.bass_rating
              // }
              {...register("bass_rating")}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">MSP voltage</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              size="small"
              // value={formik.values.msp_voltage}
              name="msp_voltage"
              {...register("msp_voltage")}
              // onChange={formik.handleChange}
            >
              {Msp?.map((volt) => (
                <MenuItem key={volt.value} value={volt.value}>
                  {volt.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Meter number</InputLabel>
            <TextField
              size="small"
              fullWidth
              id="input-with-icon-textfield"
              name="meter_number"
              type="number"
              // value={formik.values.meter_number}
              // onChange={formik.handleChange}
              // error={
              //   formik.touched.meter_number &&
              //   Boolean(formik.errors.meter_number)
              // }
              // helperText={
              //   formik.touched.meter_number && formik.errors.meter_number
              // }
              {...register("meter_number")}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Notes</InputLabel>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              style={{ width: "100%" }}
              name="notes"
              // value={formik.values.notes}
              // onChange={formik.handleChange}
              // error={formik.touched.notes && Boolean(formik.errors.notes)}
              // helperText={formik.touched.notes && formik.errors.notes}
              {...register("notes")}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Service Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              size="small"
              // value={formik.values.msp_voltage}
              name="service_type"
              // onChange={formik.handleChange}
              {...register("service_type")}
            >
              {ServieType?.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">close up photos</InputLabel>
            <MyButton variant="contained" component="label" fullWidth>
              Upload <PhotoCameraIcon />
              <input
                size="small"
                fullWidth
                hidden
                id="input-with-icon-textfield"
                name="main_panel"
                multiple
                type="file"
                onChange={(e) => {
                  handleFileEventcloseUpPhotos(e);
                }}
              />
            </MyButton>

            {closeUpPhotos.map((file) => (
              <div>{file.name}</div>
            ))}
          </Grid>

          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Wide photos</InputLabel>
            <MyButton variant="contained" component="label" fullWidth>
              Upload <PhotoCameraIcon />
              <input
                size="small"
                multiple
                hidden
                fullWidth
                id="input-with-icon-textfield"
                name="main_panel"
                type="file"
                onChange={(e) => {
                  handleFileEventwidePhotos(e);
                }}
              />
            </MyButton>

            {widePhotos.map((file) => (
              <div>{file.name}</div>
            ))}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Sub panel photos</InputLabel>
            <MyButton variant="contained" component="label" fullWidth>
              Upload <PhotoCameraIcon />
              <input
                size="small"
                fullWidth
                hidden
                id="input-with-icon-textfield"
                name="main_panel"
                type="file"
                multiple
                onChange={(e) => {
                  handleFileEventPanelSub(e);
                }}
              />
            </MyButton>

            {panelSub.map((file) => (
              <div>{file.name}</div>
            ))}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Layout Sketch</InputLabel>
            <MyButton variant="contained" component="label" fullWidth>
              {" "}
              <input
                size="small"
                type="file"
                hidden
                fullWidth
                id="input-with-icon-textfield"
                name="layout_sketch"
                multiple
                onChange={(e) => {
                  handleFileEvent(e);
                }}
                // error={
                //   formik.touched.layout_sketch &&
                //   Boolean(formik.errors.layout_sketch)
                // }
                // helperText={
                //   formik.touched.layout_sketch && formik.errors.layout_sketch
                // }
              />
              Upload <PhotoCameraIcon />
            </MyButton>

            {sketchFiles.map((file) => (
              <div>{file.name}</div>
            ))}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <InputLabel htmlFor="component-simple">Other photos</InputLabel>
            <MyButton variant="contained" component="label" fullWidth>
              Upload <PhotoCameraIcon />
              <input
                hidden
                size="small"
                type="file"
                fullWidth
                id="input-with-icon-textfield"
                name="other_photos"
                multiple
                onChange={(e) => {
                  handleFileEventOtherPhotos(e);
                }}
              />
            </MyButton>

            {otherPhotos.map((file) => (
              <div>{file.name}</div>
            ))}
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <SubmitButton
              sx={{ padding: "10px 0", width: "100%" }}
              type="submit"
            >
              Submit
            </SubmitButton>
          </Grid>
        </Grid>
      </Typography>
    </form>
  );
};

export default SiteSurvey;
