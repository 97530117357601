import { configureStore } from "@reduxjs/toolkit";
import regSliceReducer from "../Slices/RegisterSlice";
import addLicenseReducer from "../Slices/AddLicense";
import authReducer from "../Slices/AuthSlice";
import StatesReducer from "../Slices/StatesSlice";
import WorkignDaysReducer from "../Slices/AddWorkingDays";
import AddersReducer, { GetِAddersQuery } from "../Slices/Adders";
import OrdersReducer from "../Slices/installerOrders";
import ProfileReducer from "../Slices/ProfileSlice";
import SiteServeiesReducer from "../Slices/SiteServy";
import { GetProfileQuery } from "../Slices/ProfileSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
export const store = configureStore({
  reducer: {
    register: regSliceReducer,
    license: addLicenseReducer,
    auth: authReducer,
    states: StatesReducer,
    workingDays: WorkignDaysReducer,
    Adders: AddersReducer,
    Orders: OrdersReducer,
    Profile: ProfileReducer,
    sitServey: SiteServeiesReducer,
    [GetProfileQuery.reducerPath]: GetProfileQuery.reducer,
    [GetِAddersQuery.reducerPath]: GetِAddersQuery.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      GetProfileQuery.middleware,
      GetِAddersQuery.middleware
    ),
});
setupListeners(store.dispatch);
