import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Grid, styled, TextField } from "@mui/material";
import FirstForm from "./FirstForm";
import { BigSection } from "./LeftSideStyle";
import SecondForm from "./SecondForm";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CheckIcon from "@mui/icons-material/Check";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    paddingLeft: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage:
      //   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage:
      //   "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 49,
    border: 0,
    width: 2,
    backgroundColor: "white",
    // marginLeft: "3px",
    // backgroundColor:
    //   theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 3,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#7F56D9",
  zIndex: 1,
  color: "#ffffff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#7F56D9",
    // "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    // backgroundImage:
    //   "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    background: "#F9F5FF",
    border: "3px solid #7F56D9",
  }),
}));
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    // 1: <h1>ggs</h1>,
    // 2: <h1>gddddgs</h1>,
    // 3: <h1>ssss</h1>,
  };
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <CheckIcon className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
const LeftSide = () => {
  const labels = [
    "Register",
    "Bussiness License",
    "Working Days",
    "Adders Rate",
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return <FirstForm handleNext={handleNext} />;
      case 1:
        return <SecondForm handleNext={handleNext} />;
      // case 2:
      //   return <Confirm />
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <>
      {activeStep === labels.length ? (
        <h1>Success</h1>
      ) : (
        <>
          <BigSection>
            <Grid
              container
              columns={12}
              direction="row"
              // alignItems="stretch"
              // justifyContent="center"
            >
              <Grid item lg={5} md={5} xs={12}>
                <div className="leftside">
                  <div className="content">
                    <h2>Register</h2>
                    <p>Please Fill Your Data </p>
                    <p>
                      IF you already have account please{" "}
                      <a style={{fontSize:"17px",color:"white"}} href="https://www.technician.boxbyld.tech/login">
                        Login
                      </a>
                    </p>
                    {/* <Stepper
                      activeStep={activeStep}
                      orientation="vertical"
                      connector={<ColorlibConnector />}
                    >
                      {labels.map((label) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                            sx={{ color: "white" }}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>{" "} */}
                  </div>
                </div>
              </Grid>
              <Grid item lg={7} md={7} xs={12}>
                <div className="rightside">{handleSteps(activeStep)} </div>
              </Grid>
            </Grid>
          </BigSection>
        </>
      )}
    </>
  );
};

export default LeftSide;
