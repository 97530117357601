export const mainUrl = "https://tech.boxbyld.tech";

export const register = `${mainUrl}/api/auth/register`;

export const license = `${mainUrl}/api/businessLicense/store`;

export const login = `${mainUrl}/api/auth/login`;

export const states = `${mainUrl}/api/states`;

export const working_days = `${mainUrl}/api/workingDays/create`;

export const clear_working_days = `${mainUrl}/api/workingDays/delete-day`;

export const adders = `${mainUrl}/api/adders`;

export const get_all_adders_zoka = `${mainUrl}/api/adders/get-adders`;

export const add_installer_adder = `${mainUrl}/api/A/store`;

export const orederInstallerPending = `${mainUrl}/api/installerOrders/pendingOrdersAuthInstaller`;

export const orederInstallerApproved = `${mainUrl}/api/installerOrders/approvedOrdersAuthInstaller`;

export const orederInstallerAll = `${mainUrl}/api/installerOrders/showAuthInstaller`;

// export const single_order = `${mainUrl}/api/installerOrders/orderDetails`;

export const single_order = `${mainUrl}/api/installerOrders/single-work-order`;

export const order_status = `${mainUrl}/api/installerOrders/update-action`;

export const addAdder = `${mainUrl}/api/A/addAdder`;

export const GetAddedAdders = `${mainUrl}/api/A/showAuthInstaller`;

export const GetWorkignDays = `${mainUrl}/api/workingDays/authUser`;

export const GetUser = `${mainUrl}/api/user`;

export const GetLicenses = `${mainUrl}/api/businessLicense/LicenseForAuthUser`;

export const Getuser = `${mainUrl}/api/user`;

export const site_servy = `${mainUrl}/api/site-surveys`;

export const get_site_servy = `${mainUrl}/api/site-surveys/details`;

export const formData = `https://crm.boxbyld.tech/api/v1/roofing-proposals/form-data`;

export const edit_adder = `${mainUrl}/api/A/update`;
