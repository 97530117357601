import { TextField } from "@mui/material";
import styled from "styled-components";

export const Header = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  color: #484844;
`;
export const BigSection = styled.div`
  .notes {
    font-weight: 400;
    font-size: 14px;
    color: #667085;
  }
  .container {
    span {
      font-size: 14px;
      /* background: #f9f5ff; */
      border-radius: 16px;
      color: #6941c6;
      /* padding: 2px 8px; */
    }
    .firstdiv {
      background: #ffffff;
      border: 1px solid #e4e7ec;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      margin-top: 2rem;
      padding: 2rem 1rem;
    }
    .seconddiv {
      background: #ffffff;
      border: 1px solid #e4e7ec;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 0;
      padding: 1rem;
      border-top: 1px solid #e4e7ec;
      border-bottom: 1px solid #e4e7ec;
      .containerdiv {
        display: flex;
        .left {
          align-self: flex-start;
        }
        .right {
          margin-left: auto;
        }
      }
      button {
        padding: 10px 16px;
        background: #f9fafb;
        border: 1px solid #d0d5dd;
        border-radius: 8px 0px 0px 8px;
        color: #1d2939;
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        margin-left: -0.29rem;
        cursor: pointer;
      }
      .middlebutton {
        padding: 10px 16px;
        background: #f9fafb;
        border: 1px solid #d0d5dd;
        border-radius: 0;
        color: #1d2939;
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        margin-left: -0.29rem;
      }
      .finalbutton {
        border-radius: 0px 8px 8px 0px;
      }
    }
    .thirddiv {
      background: #ffffff;
      border: 1px solid #e4e7ec;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 0;
      padding: 1rem;
      border-top: 1px solid #e4e7ec;
      border-bottom: 1px solid #e4e7ec;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      min-height: 5rem;
      #customers {
        /* font-family: Arial, Helvetica, sans-serif; */
        border-collapse: collapse;
        width: 100%;
      }

      #customers td,
      #customers th {
        border: 1px solid #ddd;
        padding: 8px;
      }

      #customers tr:nth-child(even) {
        background-color: #fbfbfb;
      }

      #customers tr:hover {
        background-color: #ddd;
      }

      #customers th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #f9f5ff;
        color: #7753cb;
        font-size: 14px;
      }
      .body {
        font-size: 12px;
      }
    }
  }
  .sitebtn {
    font-size: 1rem;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #344054;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 16px;
  }
`;
export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgba(16, 24, 40, 0.05)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgba(16, 24, 40, 0.05)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(16, 24, 40, 0.05)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(16, 24, 40, 0.05)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(16, 24, 40, 0.05)",
    },
  },
});
export const PdfButton = styled.button`
  background-color: white;
  color: black;
  padding: 5px 8px;
  /* border-radius: 25px; */
  cursor: pointer;
  border: none;
  color: blue;
`;
export const MyTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
`;
