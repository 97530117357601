import { Grid, MenuItem, TextField } from "@mui/material";
import React from "react";
import { availability, is_adder, price_type, unit_array } from "./staticArray";
import { useForm } from "react-hook-form";
import { Watch } from "@mui/icons-material";
import { Edit_Adder, GetInstallerAddedAdders } from "../../Redux/Slices/Adders";
import { useDispatch, useSelector } from "react-redux";
import { AdderStyle } from "./EditAdderStyle";
import Lottie from "lottie-react";
import loaderlottie from "../../Components/Loader/loader.json";
const EditAdder = ({ singleAdder, closeModal }) => {
  console.log("singleAdder in edit", singleAdder);
  console.log("unit_array", unit_array);
  const dispatch = useDispatch();
  const { editStatus } = useSelector((state) => state.Adders);
  console.log("editStatus", editStatus);
  const [adder, setAdder] = React.useState({
    price: singleAdder?.pivot?.price,
    unit: singleAdder?.pivot?.unit,
    price_type: singleAdder?.pivot?.price_type,
    is_adder: singleAdder?.pivot?.is_adder,
    availability: singleAdder?.pivot?.availability,
  });
  console.log("singleAddersingleAdder", singleAdder);
  React.useEffect(() => {
    adder.price = singleAdder?.pivot?.price;
    adder.unit = singleAdder?.pivot?.unit;
    adder.price_type = singleAdder?.pivot?.price_type;
    adder.is_adder = singleAdder?.pivot?.is_adder;
    adder.availability = singleAdder?.pivot?.availability;
  }, [singleAdder]);
  let sendData = {
    id: singleAdder?.id,
    data: adder,
  };
  const submit = async (e) => {
    e.preventDefault();
    await dispatch(Edit_Adder(sendData));
    await dispatch(GetInstallerAddedAdders());
    if (editStatus === "success") closeModal();
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setAdder({ ...adder, [name]: value });
  };
  return (
    <>
      {editStatus !== "loading" && (
        <>
          <AdderStyle>
            {" "}
            <form onSubmit={submit}>
              <Grid container columns={12} spacing={2}>
                <Grid item md={4} xs={6}>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    placeholder="Price"
                    variant="outlined"
                    name="price"
                    label="Price"
                    fullWidth
                    value={adder?.price}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={4} xs={6}>
                  {" "}
                  <TextField
                    select
                    defaultValue=""
                    id="outlined-basic"
                    placeholder="Unit"
                    variant="outlined"
                    name="unit"
                    label="Unit"
                    fullWidth
                    value={adder?.unit}
                    onChange={handleChange}
                  >
                    {unit_array.map((serve) => (
                      <MenuItem key={serve.value} value={serve.value}>
                        {serve.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    select
                    // size="small"
                    defaultValue=""
                    label="price type"
                    name="price_type"
                    fullWidth
                    value={adder?.price_type}
                    onChange={handleChange}
                  >
                    {price_type.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    select
                    // size="small"
                    defaultValue=""
                    label="Is Adder"
                    name="is_adder"
                    fullWidth
                    onChange={handleChange}
                  >
                    {is_adder.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={6}>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    select
                    defaultValue=""
                    // size="small"
                    fullWidth
                    label="availability"
                    name="availability"
                    onChange={handleChange}
                  >
                    {availability.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={12} style={{ textAlign: "center" }}>
                  <button fullWidth type="submit">
                    Edit
                  </button>
                </Grid>
              </Grid>
            </form>
          </AdderStyle>{" "}
        </>
      )}

      {editStatus === "loading" && (
        <>
          <Lottie loop={true} animationData={loaderlottie} />
        </>
      )}
    </>
  );
};

export default React.memo(EditAdder);
