import React from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {
  Header,
  BigSection,
  CssTextField,
} from "../JobsOrders/JobsOrdersStyle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useDispatch, useSelector } from "react-redux";
import {
  GetProfileData,
  useGetProfileDataQuery,
} from "../../Redux/Slices/ProfileSlice";
import { LicenseDiv } from "../Form/LeftSideStyle";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { license_types } from "../Form/DropDowns";
import Lottie from "lottie-react";
import loaderlottie from "../../Components/Loader/loader.json";
const Profile = () => {
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   let ignore = false;
  //   if (!ignore) dispatch(GetProfileData());
  //   return () => {
  //     ignore = true;
  //   };
  // }, []);
  const { data, error, isLoading } = useGetProfileDataQuery();
  console.log("Query", data, error, isLoading);

  // const data?.data = useSelector((state) => state.Profile.data?.data);
  // const data?.data=data
  const loader = useSelector((state) => state.Profile.status);

  data?.data?.Days?.map((e, i) => {
    // <span key={i}>{e}</span>
    console.log("ashjfbaslfa", e.name);
  });
  const workingDays = data?.data?.Days || [];
  const getType = (id) => {
    return license_types.find((e) => e.id === id);
  };
  console.log("userData?.business_licence", data?.data?.business_licence);
  return (
    <BigSection>
      {!isLoading && (
        <>
          {" "}
          <Grid container columns={12}>
            <Grid item xs={12}>
              <Header>
                Profile <CreditCardIcon></CreditCardIcon>
              </Header>
              {/* <p className="notes">
            Keep track of vendor and their security ratings.
          </p> */}
            </Grid>
            <Grid item xs={12}>
              <div className="container">
                <div className="firstdiv">
                  <h3>Personal Info</h3>
                  <Grid container item>
                    <Grid item xs={12} md={6} lg={4}>
                      {" "}
                      <p>
                        Name: <span>{data?.data?.name}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {" "}
                      <p>
                        Email: <span>{data?.data?.email}</span>
                      </p>
                    </Grid>
                  </Grid>

                  {/* <p className="notes">
                Keep track of vendor and their security ratings.
              </p> */}
                </div>
                <div className="seconddiv">
                  <h3>Comapny info</h3>
                  <Grid container item>
                    <Grid item xs={12} md={6} lg={4}>
                      {" "}
                      <p>
                        Company Name: <span>{data?.data?.company_name}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {" "}
                      <p>
                        Date of organisation:{" "}
                        <span>
                          {new Date(
                            data?.data?.date_of_origination
                          )?.toLocaleDateString()}
                        </span>
                      </p>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      {" "}
                      <p>
                        Business Fax: <span>{data?.data?.business_fax}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      {" "}
                      <p>
                        phone: <span>{data?.data?.phone}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      {" "}
                      <p>
                        Address: <span>{data?.data?.address}</span>
                      </p>
                    </Grid>
                  </Grid>
                  <div className="containerdiv">
                    {/* <div className="left">
                  {" "}
                  <button>All Licenses</button>{" "}
                  <button className="middlebutton">Working Days</button>{" "}
                  <button className="finalbutton">Adders Rate</button>
                </div> */}
                    {/* <div className="right">
                  <CssTextField
                    placeholder="Search"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div> */}
                  </div>
                </div>
                <div className="seconddiv">
                  <h3>Working Days</h3>
                  <Grid container item>
                    <Grid item xs={12} md={6} lg={4}>
                      {" "}
                      <p>
                        Days:{" "}
                        {workingDays?.map((e, i) => {
                          return (
                            <span key={i} style={{ paddingRight: "5px" }}>
                              {e?.name}
                            </span>
                          );
                        })}
                      </p>
                    </Grid>
                  </Grid>
                  <div className="containerdiv"></div>
                </div>
                <div className="thirddiv">
                  <h3>Licenses</h3>

                  <Grid container item spacing={3}>
                    {data?.data?.business_licence?.map((e, i) => {
                      return (
                        <Grid item xs={12} md={6} lg={6} key={i}>
                          <LicenseDiv>
                            <p>
                              {" "}
                              license number: <span>{e.license_number}</span>
                            </p>
                            <p>
                              {" "}
                              license state: <span>{e.state}</span>
                            </p>
                            <p>
                              {" "}
                              license Type:{" "}
                              <span>{getType(e.getType)?.name}</span>
                            </p>
                            {/* <p>
                              {" "}
                              license number: <span>{e.license_number}</span>
                            </p> */}
                            <p>
                              {" "}
                              license expiration date:{" "}
                              <span>
                                {new Date(
                                  e.license_expiration_date
                                ).toLocaleDateString("en-US", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })}
                              </span>
                            </p>
                            <div className="img">
                              {" "}
                              {/* {e?.file.license_img.includes("")} */}
                              <Gallery>
                                <Item
                                  original={e.file?.license_img}
                                  thumbnail={e.file?.license_img}
                                  width="500"
                                  height="500"
                                >
                                  {({ ref, open }) => (
                                    <img
                                      ref={ref}
                                      onClick={open}
                                      src={e.file?.license_img}
                                    />
                                  )}
                                </Item>
                              </Gallery>
                            </div>
                          </LicenseDiv>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {isLoading && (
        <>
          <Lottie loop={true} animationData={loaderlottie} />
        </>
      )}
    </BigSection>
  );
};

export default Profile;
