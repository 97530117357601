import { CircularProgress, Grid, InputLabel, TextField } from "@mui/material";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PasswordIcon from "@mui/icons-material/Password";
import { SubmitButton } from "./Layout/Submit";
import { authentication } from "../Redux/Slices/AuthSlice";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { status } = useSelector((state) => state?.auth);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(5, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(authentication(values));
      await navigate("/");
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          columns={12}
          direction="row"
          // justifyContent="center"
          // alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <p
              style={{
                textAlign: "center",
                fontWeight: "800",
                fontSize: "20px",
              }}
            >
              Welcome to Installer DashBoard
            </p>
            <InputLabel htmlFor="component-simple">Email</InputLabel>
            <TextField
              id="input-with-icon-textfield"
              // label="Email"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              name="email"
              onChange={formik.handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="component-simple">Password</InputLabel>
            <TextField
              id="input-with-icon-textfield"
              // label="Password"
              type="password"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon />
                  </InputAdornment>
                ),
              }}
              name="password"
              onChange={formik.handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              type="submit"
              style={{ width: "100%", background: "#4c92e4", border: "none" }}
              endIcon={
                status === "loading" ? (
                  <CircularProgress
                    size={20}
                    thickness={10}
                    style={{ color: "#cfe3ff" }}
                  />
                ) : null
              }
            >
              Submit
            </SubmitButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default LoginForm;
