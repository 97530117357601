import styled from "styled-components";

export const Bigsection = styled.div`
  #customers {
    /* font-family: Arial, Helvetica, sans-serif; */
    border-collapse: collapse;
    width: 100%;
  }

  #customers td,
  #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #customers tr:nth-child(even) {
    background-color: #fbfbfb;
  }

  #customers tr:hover {
    background-color: #ddd;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f9f5ff;
    color: #7753cb;
    font-size: 14px;
    text-transform: uppercase;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 15px;
    color: #0000c0;
    text-transform: capitalize;
  }
`;
