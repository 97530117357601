import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
*{
  box-sizing: border-box;
 padding: 0;
 margin: 0;
 font-family: 'Inter';
 
}

body{
font-family: 'Beau Rivage', cursive;
font-family: 'Inter', sans-serif;
font-style: normal;

}
    /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 0;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #929eae ; 
  border-radius: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0138b7; 

}
.css-1azl6jz-MuiTableCell-root {
  text-align: center !important;
}
.css-1azl6jz-MuiTableCell-root{
  text-align: center !important;

}

.css-177gid-MuiTableCell-root{
  text-align: center !important;
}
.css-10dfkli-MuiTableCell-root {
  text-align: center !important;
}

`;
