import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  addAdder,
  adders,
  add_installer_adder,
  edit_adder,
  GetAddedAdders,
  get_all_adders_zoka,
  states,
} from "../../Api";
import { toast } from "react-toastify";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const GetAdders = createAsyncThunk(
  "Adders/get",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${get_all_adders_zoka}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("licenses has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetAddersZoka = createAsyncThunk(
  "Adders/getZokaAdders",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${get_all_adders_zoka}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("licenses has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const InstallerAdder = createAsyncThunk(
  "AddersInstaller/post",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${add_installer_adder}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success("Adders has been Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AddNewAdder = createAsyncThunk(
  "AddNewAdder/post",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${addAdder}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success("Adder has been Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetInstallerAddedAdders = createAsyncThunk(
  "AddedAdders/GET",
  async (_, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${GetAddedAdders}`,
        headers: headers,
      }).then((res) => {
        // toast.success("Adder has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Edit_Adder = createAsyncThunk(
  "Adders/EDIT",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${edit_adder}/${data?.id}`,
        headers: headers,
        data: data?.data,
      }).then((res) => {
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// RTK
export const GetِAddersQuery = createApi({
  reducerPath: "GetِAddersQuery",
  baseQuery: fetchBaseQuery({
    baseUrl: get_all_adders_zoka,
    prepareHeaders: (headers) => {
      const user = JSON.parse(localStorage.getItem("installer"));
      if (user?.token) {
        headers.set("authorization", `Bearer ${user.token}`);
        headers.set("Content-type", "appliation/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAdderseData: builder.query({
      query: () => "",
    }),
  }),
});

const Adders = createSlice({
  name: "Adders",
  initialState: {
    adders: "",
    status: "",
    addedAdders: "",
    addAdder: "",
    editAdder: "",
    zokaAdders: [],
  },
  reducers: {
    adderReset: (state) => {
      state.status = "";
    },
  },
  extraReducers: {
    [GetAdders.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetAdders.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.adders = payload.data;
    },
    [GetAdders.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [GetAddersZoka.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetAddersZoka.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.zokaAdders = payload.data;
    },
    [GetAddersZoka.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [InstallerAdder.pending]: (state, { payload }) => {
      state.addAdder = "loading";
      state.status = "loading";
    },
    [InstallerAdder.fulfilled]: (state, { payload }) => {
      state.addAdder = "success";
      state.addersInstaller = payload.data;
    },
    [InstallerAdder.rejected]: (state, { payload }) => {
      state.addAdder = "failed";
      state.status = "failed";
    },
    [GetInstallerAddedAdders.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetInstallerAddedAdders.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.addedAdders = payload.data;
    },
    [GetInstallerAddedAdders.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [Edit_Adder.pending]: (state, { payload }) => {
      state.editStatus = "loading";
    },
    [Edit_Adder.fulfilled]: (state, { payload }) => {
      state.editStatus = "success";
      state.editAdder = payload.data;
    },
    [Edit_Adder.rejected]: (state, { payload }) => {
      state.editStatus = "failed";
      state.error = payload;
    },
  },
});
export const { adderReset } = Adders.actions;
export default Adders.reducer;
export const { useGetAdderseDataQuery } = GetِAddersQuery;
