import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SubmitButton } from "../Layout/Submit";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Swal from "sweetalert2";
import { AddWorkingDays } from "../../Redux/Slices/AddWorkingDays";
import * as yup from "yup";
import { useFormik } from "formik";
import Lottie from "lottie-react";
import loaderlottie from "../../Components/Loader/loader.json";
import {
  adderReset,
  GetAdders,
  GetAddersZoka,
  GetInstallerAddedAdders,
  InstallerAdder,
  useGetAdderseDataQuery,
} from "../../Redux/Slices/Adders";
import { AddMore, Div, Style } from "./LeftSideStyle";
import { CheckBox } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditAdder from "./EditAdder";
import { availability, is_adder, price_type, unit_array } from "./staticArray";
const FourthForm = () => {
  const dispatch = useDispatch();
  const { data, error, isLoading } = useGetAdderseDataQuery();
  console.log("Query data", data?.data);
  // React.useEffect(() => {
  //   let api = async () => {
  //     // await dispatch(GetAdders());
  //     // await dispatch(GetInstallerAddedAdders());
  //     // await dispatch(adderReset());
  //     await dispatch(GetAddersZoka());
  //   };
  //   api();
  // }, []);
  const loader = useSelector((state) => state.Adders.status);
  const status = useSelector((state) => state.Adders.addAdder);

  const InstallerData = JSON.parse(localStorage.getItem("installer"));
  const allAdders = useSelector((state) => state.Adders.adders) || [];
  const newAllAdders = allAdders.filter((e) => e.status === "1") || [];
  const addedAdders = useSelector((state) => state.Adders?.addedAdders) || [];
  const { zokaAdders } = useSelector((state) => state.Adders);
  console.log("zokaAdders", zokaAdders);
  let AddedaddersArr = [];
  addedAdders.map((e) => {
    return (AddedaddersArr = e.adders);
  });
  const [singleAdder, setSingleAdder] = React.useState();
  const [adders, setAdders] = React.useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSingleAdder({ ...singleAdder, [name]: value });
  };

  const handleAddAdder = (e) => {
    e.preventDefault();
    setAdders((preValue) => [
      ...preValue,
      {
        adder_id: singleAdder?.adder_id,
        price: singleAdder?.price,
        unit: singleAdder?.unit,
      },
    ]);
    setSingleAdder(null);
  };
  const findName = (id) => {
    return allAdders.find((e) => e.id === id);
  };

  const [checked, setChecked] = React.useState();
  const [newAdder, setNewAdder] = React.useState();

  const deleteAdder = (i) => {
    const updatedlist = [...adders];
    if (i > -1) {
      updatedlist.splice(i, 1);
    }
    setAdders(updatedlist);
    console.log("updatedlist", updatedlist);
  };
  // Try useRef
  // const arrLength = arr.length;
  // const [elRefs, setElRefs] = React.useState([]);
  // const arrLength = newAllAdders?.length;
  // if (elRefs.current?.length > 0) {
  //   if (elRefs.current?.length !== arrLength) {
  //     // add or remove refs
  //     elRefs.current = Array(arrLength)
  //       .fill()
  //       .map((_, i) => elRefs?.current[i] || React.createRef());
  //   }
  // }
  // End Of Try
  const [sendAdders, setSendAdders] = React.useState([]);
  const [editAdder, setEditAdder] = React.useState("");

  const checkValue = (event) => {
    if (event) {
      var updatedList = [...sendAdders];
      if (event.target.checked) {
        updatedList = [...sendAdders, { adder_id: event.target.value }];
      } else {
        updatedList.splice(
          sendAdders.indexOf(
            sendAdders.find((e) => e.adder_id === event.target.value)
          ),
          1
        );
      }
      setSendAdders(updatedList);
      // valueRef.current.value.push(updatedList);
    }
  };
  const UpdateValue = React.useCallback(
    (event, adder_id) => {
      const { name, value } = event.target;
      console.log("check this", name, value);
      const newArr = sendAdders.map((obj) => {
        if (Number(obj.adder_id) === adder_id) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
      setSendAdders(newArr);
    },
    [sendAdders]
  );

  const sendDate = {
    data: sendAdders,
  };

  React.useEffect(() => {
    if (status === "success") {
      setSendAdders([]);
    }
  }, [status]);

  const handleSubmit = async () => {
    if (sendAdders.length > 0) {
      await dispatch(InstallerAdder({ ...sendDate }));
      await dispatch(GetInstallerAddedAdders());
      await dispatch(adderReset());
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Need to Select at least one Adder",
      });
    }
  };

  let myArray = newAllAdders.filter(
    (ar) => !AddedaddersArr.find((rm) => rm.id === ar.id)
  );
  // console.log("myArray", myArray);
  // console.log("newAllAdders", newAllAdders);
  const newAdders = data?.data;
  console.log("newAdders : ", newAdders);
  return (
    <div>
      {!isLoading && (
        <div>
          <div>
            <Grid container columns={12} spacing={3} sx={{ padding: "0 0" }}>
              {/* left Side */}
              <Grid item xs={12}>
                {" "}
                <Grid item xs={12}>
                  <form onSubmit={handleAddAdder}>
                    <Grid
                      container
                      columns={12}
                      spacing={3}
                      // sx={{ padding: "0 3rem" }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={12}>
                        <h3
                          style={{ textAlign: "center", marginBottom: "1rem" }}
                        >
                          {" "}
                          Adders
                        </h3>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 150 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Select</TableCell>
                                <TableCell align="center">Adder</TableCell>
                                <TableCell align="center">Price</TableCell>
                                <TableCell align="center">Unit</TableCell>
                                <TableCell align="center">Price type</TableCell>
                                <TableCell align="center">Is adder</TableCell>
                                <TableCell align="center">
                                  Availability
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {newAdders?.map((adder, i) => {
                                return (
                                  <>
                                    {" "}
                                    <TableRow key={i}>
                                      <TableCell colspan="7" align="center">
                                        <h2>
                                          {adder?.service_type
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                            adder?.service_type?.slice(1)}{" "}
                                          adders
                                        </h2>
                                      </TableCell>
                                    </TableRow>
                                    {adder?.adders_without_installer?.map(
                                      (newAdder, index) => {
                                        return (
                                          <TableRow
                                            key={index}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                            // ref={elRefs.current[i]}
                                          >
                                            <TableCell align="center">
                                              <Checkbox
                                                value={newAdder?.id}
                                                onChange={checkValue}
                                                disabled={
                                                  AddedaddersArr.find(
                                                    (e) => e.id === adder.id
                                                  )
                                                    ? true
                                                    : false
                                                }
                                              ></Checkbox>
                                            </TableCell>
                                            <TableCell align="center">
                                              {newAdder.name}
                                            </TableCell>
                                            <TableCell align="right">
                                              <TextField
                                                sx={{
                                                  width: { sm: 100, md: 100 },
                                                  "& .MuiInputBase-root": {
                                                    // height: 20,
                                                  },
                                                }}
                                                id="outlined-basic"
                                                placeholder="Price"
                                                variant="outlined"
                                                size="small"
                                                name="price"
                                                disabled={
                                                  AddedaddersArr.find(
                                                    (e) => e.id === newAdder.id
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  UpdateValue(e, newAdder.id);
                                                }}
                                              />{" "}
                                            </TableCell>
                                            <TableCell align="right">
                                              <TextField
                                                sx={{
                                                  width: { sm: 100, md: 100 },
                                                  "& .MuiInputBase-root": {
                                                    // height: 20,
                                                  },
                                                }}
                                                select
                                                id="outlined-basic"
                                                placeholder="Unit"
                                                variant="outlined"
                                                size="small"
                                                name="unit"
                                                disabled={
                                                  AddedaddersArr.find(
                                                    (e) => e.id === newAdder.id
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  UpdateValue(e, newAdder.id);
                                                }}
                                                // defaultValue="per panel"
                                              >
                                                {unit_array.map((serve) => (
                                                  <MenuItem
                                                    key={serve.value}
                                                    value={serve.value}
                                                  >
                                                    {serve.name}
                                                  </MenuItem>
                                                ))}
                                              </TextField>{" "}
                                            </TableCell>
                                            <TableCell align="right">
                                              <TextField
                                                sx={{
                                                  width: { sm: 100, md: 100 },
                                                  "& .MuiInputBase-root": {
                                                    // height: 20,
                                                  },
                                                }}
                                                id="outlined-basic"
                                                variant="outlined"
                                                select
                                                size="small"
                                                // defaultValue="labor and material"
                                                name="price_type"
                                                disabled={
                                                  AddedaddersArr.find(
                                                    (e) => e.id === adder.id
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  UpdateValue(e, newAdder.id);
                                                }}
                                              >
                                                {price_type.map((type) => (
                                                  <MenuItem
                                                    key={type.value}
                                                    value={type.value}
                                                  >
                                                    {type.name}
                                                  </MenuItem>
                                                ))}
                                              </TextField>
                                            </TableCell>
                                            <TableCell align="right">
                                              <TextField
                                                sx={{
                                                  width: { sm: 100, md: 100 },
                                                  "& .MuiInputBase-root": {
                                                    // height: 20,
                                                  },
                                                }}
                                                id="outlined-basic"
                                                variant="outlined"
                                                select
                                                size="small"
                                                // defaultValue="adder"
                                                disabled={
                                                  AddedaddersArr.find(
                                                    (e) => e.id === newAdder.id
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                name="is_adder"
                                                onChange={(e) => {
                                                  UpdateValue(e, newAdder.id);
                                                }}
                                              >
                                                {is_adder.map((type) => (
                                                  <MenuItem
                                                    key={type.value}
                                                    value={type.value}
                                                  >
                                                    {type.name}
                                                  </MenuItem>
                                                ))}
                                              </TextField>
                                            </TableCell>
                                            <TableCell align="right">
                                              <TextField
                                                sx={{
                                                  width: { sm: 100, md: 100 },
                                                  "& .MuiInputBase-root": {
                                                    // height: 20,
                                                  },
                                                }}
                                                id="outlined-basic"
                                                variant="outlined"
                                                select
                                                size="small"
                                                // defaultValue={1}
                                                disabled={
                                                  AddedaddersArr.find(
                                                    (e) => e.id === newAdder.id
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                // size="small"
                                                name="availability"
                                                onChange={(e) => {
                                                  UpdateValue(e, newAdder.id);
                                                }}
                                              >
                                                {availability.map((type) => (
                                                  <MenuItem
                                                    key={type.value}
                                                    value={type.value}
                                                  >
                                                    {type.name}
                                                  </MenuItem>
                                                ))}
                                              </TextField>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        <SubmitButton onClick={handleSubmit} type="button">
                          Submit
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                {/* <Grid item xs={12}>
                  {adders.map((e, i) => {
                    return (
                      <Div key={i}>
                        <p>
                          ${e.price} <span>{findName(e.adder_id).name}</span>
                        </p>
                        <div className="btn">
                          <button onClick={() => deleteAdder(i)}>
                            asdsadasdsad
                            <DeleteIcon></DeleteIcon>{" "}
                          </button>
                        </div>
                      </Div>
                    );
                  })}
                </Grid> */}
                {adders.length > 0 && (
                  <>
                    <Grid
                      container
                      item
                      columns={12}
                      style={{ padding: "2rem 8rem" }}
                    >
                      <Grid item xs={6} style={{ textAlign: "center" }}>
                        <SubmitButton
                          style={{ background: "white", color: "#7f56d9" }}
                        >
                          Cancel
                        </SubmitButton>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "center" }}>
                        <SubmitButton onClick={handleSubmit} type="button">
                          Submit
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      {isLoading && (
        <>
          <Lottie loop={true} animationData={loaderlottie} />
        </>
      )}
    </div>
  );
};

export default FourthForm;
