import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { formData, get_site_servy, site_servy } from "../../Api";
import { toast } from "react-toastify";

export const siteSurvey = createAsyncThunk(
  "siteServy/post",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": " *",
      };
      return await axios({
        method: "POST",
        baseURL: `${site_servy}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success("Site Servey has been created", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetsiteSurvey = createAsyncThunk(
  "siteServy/get",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": " *",
      };
      return await axios({
        method: "GET",
        baseURL: `${get_site_servy}/${data}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("Site Servey has been created", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetFormData = createAsyncThunk(
  "formData/get",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": " *",
      };
      return await axios({
        method: "GET",
        baseURL: `${formData}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("Site Servey has been created", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const SiteServeies = createSlice({
  name: "States",
  initialState: {
    states: "",
    status: "",
    SingleSiteSurvey: "",
    formData: "",
  },
  reducers: {},
  extraReducers: {
    [siteSurvey.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [siteSurvey.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.states = payload.data;
    },
    [siteSurvey.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [GetsiteSurvey.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetsiteSurvey.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.SingleSiteSurvey = payload.data;
    },
    [GetsiteSurvey.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [GetFormData.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetFormData.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.formData = payload.data;
    },
    [GetFormData.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});
export default SiteServeies.reducer;
