import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GetLicenses, license } from "../../Api";
import { toast } from "react-toastify";

export const AddLicense = createAsyncThunk(
  "add/license",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${license}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success("licenses has been Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GetLicense = createAsyncThunk(
  "add/license",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      console.log("headersheaders", headers);
      console.log("useruseruser", user);

      return await axios({
        method: "GET",
        baseURL: `${GetLicenses}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("licenses has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const License = createSlice({
  name: "license",
  initialState: {
    licenseData: "",
    allLicenses: "",
    add_liecnse_status:""
  },
  reducers: {},
  extraReducers: {
    [AddLicense.pending]: (state, { payload }) => {
      state.add_liecnse_status = "loading";
    },
    [AddLicense.fulfilled]: (state, { payload }) => {
      state.add_liecnse_status = "success";
      state.licenseData = payload.data;
    },
    [AddLicense.rejected]: (state, { payload }) => {
      state.add_liecnse_status = "failed";
      state.error = payload;
    },
    [GetLicense.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetLicense.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.allLicenses = payload.data;
    },
    [GetLicense.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});
export default License.reducer;
