import "./App.css";
import { Routes, Route } from "react-router-dom";
import Register from "./Pages/Register/Register";
import { GlobalStyle } from "./Style/GlobalStyle";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Pages/Register/Login/Login";
import { useSelector } from "react-redux";
import LeftNavbar from "./Components/LeftNavbar/LeftNavbar";
import { Navigate } from "react-router-dom";
import SecondForm from "./Components/Form/SecondForm";
import ThirdForm from "./Components/Form/ThirdForm";
import FourthForm from "./Components/Form/FourthForm";
import JobsOrders from "./Components/JobsOrders/JobsOrders";
import PendingOrders from "./Components/JobsOrders/PendingOrders";
import ApprovedOrders from "./Components/JobsOrders/ApprovedOrders";
import Profile from "./Components/Profile/Profile";
import AllLicense from "./Components/Profile/AllLicense";
import AllAddersRate from "./Components/Profile/AllAddersRate";
import Docs from "./Components/Docs/Docs";
import SiteSurvey from "./Components/installerNewForms/SiteSurvey";
import MyAdders from "./Components/MY Adders/MyAdders";

function App() {
  const isAutherized = useSelector((state) => state.auth?.data);

  return (
    <>
      <GlobalStyle />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isAutherized ? (
        <>
          <Routes>
            <Route path="/" element={<Navigate to="/home/profile" />} />

            <Route path="/home" element={<LeftNavbar></LeftNavbar>}>
              <Route path="profile" element={<Profile></Profile>}>
                <Route
                  path="licenses"
                  element={<AllLicense></AllLicense>}
                ></Route>
                <Route
                  path="licenses"
                  element={<AllAddersRate></AllAddersRate>}
                ></Route>
              </Route>
              <Route
                path="licensce"
                element={<SecondForm></SecondForm>}
              ></Route>
              <Route
                path="working-days"
                element={<ThirdForm></ThirdForm>}
              ></Route>
              <Route
                path="adders-rate"
                element={<FourthForm></FourthForm>}
              ></Route>
              <Route
                path="my-adders"
                element={<MyAdders></MyAdders>}
              ></Route>
              <Route path="jobs-orders" element={<JobsOrders></JobsOrders>}>
                <Route
                  path="site-survey/:id/:id"
                  element={<SiteSurvey></SiteSurvey>}
                ></Route>
              </Route>
              <Route path="docs" element={<Docs></Docs>}></Route>
            </Route>
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/register" element={<Register></Register>}></Route>
            <Route path="/login" element={<Login></Login>}></Route>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
