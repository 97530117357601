import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SubmitButton } from "../Layout/Submit";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Swal from "sweetalert2";
import {
  AddWorkingDays,
  ClearWorkingDays,
  GetWorkignDaysInstaler,
  workingDaysReset,
} from "../../Redux/Slices/AddWorkingDays";
import Lottie from "lottie-react";
import loaderlottie from "../../Components/Loader/loader.json";
import { MyTable } from "../JobsOrders/JobsOrdersStyle";
import DeleteIcon from "@mui/icons-material/Delete";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ThirdForm = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    let apis = async () => {
      await dispatch(GetWorkignDaysInstaler());
      await dispatch(workingDaysReset());
    };
    apis();
  }, []);
  const [days, setDays] = React.useState([]);
  const [dayId, setDayID] = React.useState("");
  const workingDays =
    useSelector((state) => state.workingDays.getWorkingDays) || [];
  const loader = useSelector((state) => state.workingDays.status) || [];

  const handleChange = (event) => {
    var updatedList = [...days];
    if (event.target.checked) {
      updatedList = [...days, { day_id: event.target.value }];
    } else {
      updatedList.splice(
        days.indexOf(days.find((e) => e.day_id === event.target.value)),
        1
      );
    }
    setDays(updatedList);
  };
  const sendData = {
    day_id: days,
  };
  const getSeconds = (s) =>
    s?.split(":")?.reduce((acc, curr) => acc * 60 + +curr, 0);
  console.log("Check", (getSeconds("21:00") - getSeconds("09:00")) / 60);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (days?.length > 0) {
      const formData = new FormData();
      days.map((e, i) => {
        formData.append(`data[${i}][day_id]`, e?.day_id);
        formData.append(`data[${i}][work_start]`, e?.work_start);
        formData.append(`data[${i}][work_end]`, e?.work_end);
        formData.append(
          `data[${i}][duration]`,
          Math.abs(getSeconds(e.work_end) - getSeconds(e.work_start)) / 60
        );
      });
      await dispatch(AddWorkingDays(formData));
      await dispatch(GetWorkignDaysInstaler());
      await dispatch(workingDaysReset());

      setDays([]);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Need to Select at least one working Days",
      });
    }
  };
  const clearAllWorkingDays = async () => {
    await dispatch(ClearWorkingDays(dayId));
    await dispatch(GetWorkignDaysInstaler());
    await dispatch(workingDaysReset());
  };

  const [newDays, setNewDays] = React.useState([]);
  console.log("new Days Array", newDays);
  console.log("Days Array", days);
  var newArr = [];
  console.log("newArr", newArr);
  const handleChangeTime = (event, day) => {
    const { name, value } = event.target;
    const newArr = days.map((obj) => {
      if (obj.day_id === day) {
        return { ...obj, [name]: value };
      }
      return obj;
    });
    setDays(newArr);
  };
  const getOject = (id) => {
    return days.find((e) => e._id === id);
  };
  console.log("workingDays", workingDays);
  return (
    <>
      {loader !== "loading" && (
        <>
          <div>
            <Grid container columns={12}>
              <Grid item xs={12} md={6} lg={6}>
                {/* <Grid container item columns={12}> */}
                <form onSubmit={handleSubmit}>
                  {/* <Grid  container columns={12}> */}
                  <h3>Working days </h3>
                  <FormGroup>
                    <Grid container columns={12}>
                      <Grid item xs={6}>
                        {" "}
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleChange}
                              value="3"
                              sx={{
                                color: "",
                                //   border: "1.6666px solid #7F56D9",
                                "&.Mui-checked": {
                                  color: "#7F56D9",
                                },
                              }}
                            />
                          }
                          label="monday"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {days.find((e) => e.day_id === "3") && (
                          <>
                            {" "}
                            <span>From</span>{" "}
                            <input
                              // id="settime"
                              name="work_start"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "3");
                              }}
                            />
                          </>
                        )}
                        {days.find((e) => e.day_id === "3") && (
                          <>
                            {" "}
                            <span>To</span>{" "}
                            <input
                              id="settime"
                              name="work_end"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "3");
                              }}
                            />
                            {/* <span>Working hours:{Math.abs(getSeconds(getOject("3"))?.work_end - getSeconds(getOject("3"))?.work_start )/60}</span> */}
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="4"
                              onChange={handleChange}
                              sx={{
                                color: "",
                                //   border: "1.6666px solid #7F56D9",
                                "&.Mui-checked": {
                                  color: "#7F56D9",
                                },
                              }}
                            />
                          }
                          label="tuesday"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        {days.find((e) => e.day_id === "4") && (
                          <>
                            {" "}
                            <span>From</span>{" "}
                            <input
                              id="settime"
                              name="work_start"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "4");
                              }}
                            />
                          </>
                        )}
                        {days.find((e) => e.day_id === "4") && (
                          <>
                            {" "}
                            <span>To</span>{" "}
                            <input
                              id="settime"
                              name="work_end"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "4");
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="5"
                              onChange={handleChange}
                              sx={{
                                color: "",
                                //   border: "1.6666px solid #7F56D9",
                                "&.Mui-checked": {
                                  color: "#7F56D9",
                                },
                              }}
                            />
                          }
                          label="wedensday"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        {days.find((e) => e.day_id === "5") && (
                          <>
                            {" "}
                            <span>From</span>{" "}
                            <input
                              id="settime"
                              name="work_start"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "5");
                              }}
                            />
                          </>
                        )}
                        {days.find((e) => e.day_id === "5") && (
                          <>
                            {" "}
                            <span>To</span>{" "}
                            <input
                              id="settime"
                              name="work_end"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "5");
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="6"
                              onChange={handleChange}
                              sx={{
                                color: "",
                                //   border: "1.6666px solid #7F56D9",
                                "&.Mui-checked": {
                                  color: "#7F56D9",
                                },
                              }}
                            />
                          }
                          label="thursday"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        {days.find((e) => e.day_id === "6") && (
                          <>
                            {" "}
                            <span>From</span>{" "}
                            <input
                              id="settime"
                              name="work_start"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "6");
                              }}
                            />
                          </>
                        )}
                        {days.find((e) => e.day_id === "6") && (
                          <>
                            {" "}
                            <span>TO</span>{" "}
                            <input
                              id="settime"
                              name="work_end"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "6");
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="7"
                              onChange={handleChange}
                              sx={{
                                color: "",
                                //   border: "1.6666px solid #7F56D9",
                                "&.Mui-checked": {
                                  color: "#7F56D9",
                                },
                              }}
                            />
                          }
                          label="friday"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        {days.find((e) => e.day_id === "7") && (
                          <>
                            {" "}
                            <span>From</span>{" "}
                            <input
                              id="settime"
                              name="work_start"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "7");
                              }}
                            />
                          </>
                        )}
                        {days.find((e) => e.day_id === "7") && (
                          <>
                            {" "}
                            <span>To</span>{" "}
                            <input
                              id="settime"
                              name="work_end"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "7");
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="1"
                              onChange={handleChange}
                              sx={{
                                color: "",
                                //   border: "1.6666px solid #7F56D9",
                                "&.Mui-checked": {
                                  color: "#7F56D9",
                                },
                              }}
                            />
                          }
                          label="saturday"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        {days.find((e) => e.day_id === "1") && (
                          <>
                            {" "}
                            <span>From</span>{" "}
                            <input
                              id="settime"
                              name="work_start"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "1");
                              }}
                            />
                          </>
                        )}
                        {days.find((e) => e.day_id === "1") && (
                          <>
                            {" "}
                            <span>To</span>{" "}
                            <input
                              id="settime"
                              name="work_end"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "1");
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="2"
                              onChange={handleChange}
                              sx={{
                                color: "",
                                //   border: "1.6666px solid #7F56D9",
                                "&.Mui-checked": {
                                  color: "#7F56D9",
                                },
                              }}
                            />
                          }
                          label="sunday"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {" "}
                        {days.find((e) => e.day_id === "2") && (
                          <>
                            {" "}
                            <span>From</span>{" "}
                            <input
                              id="settime"
                              name="work_start"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "2");
                              }}
                            />
                          </>
                        )}
                        {days.find((e) => e.day_id === "2") && (
                          <>
                            {" "}
                            <span>To</span>{" "}
                            <input
                              id="settime"
                              name="work_end"
                              type="time"
                              required
                              onChange={(e) => {
                                handleChangeTime(e, "2");
                              }}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </FormGroup>
                  <Grid
                    container
                    item
                    columns={12}
                    style={{ margin: "2rem 0" }}
                  >
                    {/* <Grid item xs={12} md={6} lg={6}style={{ textAlign: "center",width:"100%" }}>
                      <SubmitButton
                        style={{ background: "white", color: "#7f56d9" }}
                      >
                        Cancel
                      </SubmitButton>
                    </Grid> */}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ textAlign: "center", width: "90%" }}
                    >
                      <SubmitButton type="submit">Submit</SubmitButton>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              {/* </Grid> */}
              {/* </Grid> */}
              <Grid item xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
                {workingDays.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3>Working Days Info</h3>
                      </div>
                      <MyTable>
                        <tr>
                          <th>Day</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Duration (h:mm)</th> <th>Remove day</th>
                        </tr>
                        {workingDays?.map((e, i) => {
                          return (
                            <tr key={i}>
                              <td>{e?.day?.name}</td>
                              <td>{e.work_start}</td>
                              <td>{e.work_end}</td>
                              <td>{Number(e.duration)?.toFixed(2)}</td>
                              <td>
                                {" "}
                                <Button
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    setDayID(e?.day_id);
                                    handleOpen();
                                  }}
                                  endIcon={<DeleteIcon />}
                                ></Button>
                              </td>
                            </tr>
                          );
                        })}
                      </MyTable>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                Are you sure want to clear this day
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                align="center"
              >
                <button
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: "0.5rem 1rem",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={clearAllWorkingDays}
                >
                  Yes
                </button>{" "}
                <button
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    padding: "0.5rem 1rem",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                >
                  No
                </button>
              </Typography>
            </Box>
          </Modal>
        </>
      )}

      {loader === "loading" && (
        <>
          <Lottie loop={true} animationData={loaderlottie} />
        </>
      )}
    </>
  );
};

export default ThirdForm;
