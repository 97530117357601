import { Button } from "@mui/material";
import styled from "styled-components";

export const SubmitButton = styled(Button)`
  && {
    text-align: center;
    color: white;
    background-color: #7f56d9;
    width: 90%;
    text-align: center;
    border: 1px solid #7F56D9;
    border-radius: 8px;
    padding:1.0rem 0;
  }
`;
