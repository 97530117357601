import { Grid } from "@mui/material";
import React from "react";
import LoginForm from "../../../Components/LoginForm";
import Lottie from "lottie-react";
import registerLottie from "../../../Components/Layout/Lotties/Register.json";
const Login = () => {
  return (
    <div style={{margin: "0 2rem "}}>
      <Grid container columns={12} justifyContent="center" alignItems="center">
        <Grid item lg={6} md={6} xs={12}>
          <Lottie loop={true} animationData={registerLottie} />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <LoginForm></LoginForm>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
