import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  Header,
  BigSection,
  CssTextField,
  PdfButton,
  Table,
  MyTable,
} from "./JobsOrdersStyle";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeOrderStatus,
  GetAllOrders,
  GetApprovedOrders,
  GetPendingOrders,
  GetSingleOrder,
} from "../../Redux/Slices/installerOrders";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SettingsIcon from "@mui/icons-material/Settings";
import ConstructionIcon from "@mui/icons-material/Construction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Lottie from "lottie-react";
import loaderlottie from "../../Components/Loader/loader.json";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  height: "100%",
  display: "block",
};
const JobsOrders = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(GetAllOrders());
  }, []);
  const allOrders = useSelector((state) => state.Orders.allOrders) || [];
  const loader = useSelector((state) => state.Orders.status);
  let arr = [];
  let ordersArr =
    allOrders.map((e) => {
      arr = e.orders;
    }) || [];
  const [selected, setSelected] = React.useState();
  console.log("selected", selected);
  React.useEffect(() => {
    // if (selected) dispatch(GetSingleOrder(selected.pivot.job_id));
    if (selected) dispatch(GetSingleOrder(selected?.id));
  }, [selected]);
  const singleOrder =
    useSelector((state) => state.Orders.singleOrder)?.opportunity || [];
  const WorOrderJob = useSelector((state) => state.Orders.singleOrder);

  console.log("singleOrder", singleOrder);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const handleChange = (e) => {
  //   setStatus(e.target.value);
  // };
  const [id, setID] = React.useState("");

  const location = useLocation();
  const [status, setStatus] = React.useState([]);
  let action = {
    action: status,
  };
  let sendData = {
    id: id?.pivot?.job_id,
    data: action,
  };
  console.log("sendData", sendData);
  const handleChange = async (e, i) => {
    const newStatus = [...status];
    newStatus[i] = e.target.value;
    setStatus(newStatus);
    console.log("idididid", id);
  };
  React.useEffect(() => {
    // dispatch(ChangeOrderStatus(sendData))
  }, []);
  console.log("Status Status Status Status", status);

  return (
    <BigSection>
      {loader !== "loading" && (
        <>
          {" "}
          <Grid container columns={12}>
            <Grid item xs={12}>
              <Header>
                Job Order{" "}
                <ConstructionIcon
                  style={{ fontSize: "25px" }}
                ></ConstructionIcon>
              </Header>
              <p className="notes">
                {/* Keep track of vendor and their security ratings. */}
              </p>
            </Grid>
            <Grid item xs={12}>
              <div className="container">
                <div className="firstdiv">
                  {location.pathname.includes("site-survey") && (
                    <>
                      {" "}
                      <h3>SiteSurvey</h3>
                    </>
                  )}

                  <p>{/* Incoming Orders <span>240 vendors</span> */}</p>
                  <p className="notes">
                    {/* Keep track of vendor and their security ratings. */}
                  </p>
                </div>
                {/* <div className="seconddiv">
              <div className="containerdiv">
                <div className="left">
                  {" "}
                  <button>View All</button>{" "}
                  <button className="middlebutton">Declined</button>{" "}
                  <button className="finalbutton">Aprroved</button>
                </div>
                <div className="right">
                  <CssTextField
                    placeholder="Search"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div> */}

                <div className="thirddiv">
                  {location.pathname.includes("site-survey") ? (
                    <>
                      <Outlet></Outlet>
                    </>
                  ) : (
                    <>
                      {" "}
                      <table id="customers">
                        <tr>
                          <th>Name</th>
                          <th>Address</th>
                          <th>Phone number</th>
                          <th>Cutomer email</th>
                          <th>Pm email</th>
                          <th>Pm name</th>
                          <th>Pm phone</th>
                          <th>Details</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                        {arr.map((e, i) => {
                          return (
                            <tr className="body" key={i}>
                              <td>{e?.client_name}</td>
                              <td>{e?.client_address}</td>
                              <td>{e?.client_phone}</td>
                              <td>{e?.client_email}</td>
                              <td>{e?.pm_agent_email}</td>
                              <td>{e?.pm_agent_name}</td>
                              <td>{e?.client_email}</td>
                              <td style={{ textAlign: "center" }}>
                                {" "}
                                <button
                                  style={{
                                    border: "none",
                                    color: "blue",
                                    background: "none",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelected(e);
                                    handleOpen();
                                  }}
                                >
                                  <VisibilityIcon></VisibilityIcon>
                                </button>
                              </td>
                              <td>
                                {" "}
                                <Box sx={{ minWidth: 120 }}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      status
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={status[i] || arr[i].pivot.action}
                                      label="status"
                                      onChange={async (singleRow) => {
                                        setID(e);
                                        handleChange(singleRow, i);
                                        console.log("e", e);
                                        dispatch(
                                          ChangeOrderStatus({
                                            id: e.pivot.job_id,
                                            data: {
                                              action: singleRow.target.value,
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <MenuItem value="0">pending</MenuItem>
                                      <MenuItem value="1">In progress</MenuItem>
                                      <MenuItem value="2">completed</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </td>
                              <th style={{ background: "inherit" }}>
                                <Link to={`site-survey/${e.ticket_id}/${e.id}`}>
                                  <button className="sitebtn">
                                    site survey
                                  </button>
                                </Link>
                              </th>
                            </tr>
                          );
                        })}
                      </table>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              {/* <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                <hr></hr>
                Job Details
                <hr></hr>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                align="center"
              >
                <Grid container columns={12} spacing={2}>
                  <Grid item lg={4}>
                    <p className="key">
                      panels:{" "}
                      <span className="value">
                        {singleOrder[0]?.number_of_panels}
                      </span>
                    </p>
                  </Grid>
                  <Grid item lg={4}>
                    <p className="key">
                      Wattage:{" "}
                      <span className="value">
                        {singleOrder[0]?.panel_wattage}
                      </span>
                    </p>
                  </Grid>
                  <Grid item lg={4}>
                    <p className="key">
                      System Size:{" "}
                      <span className="value">
                        {singleOrder[0]?.system_size}
                      </span>
                    </p>
                  </Grid>
                  <Grid item lg={4}>
                    <p className="key">
                      Inverter Type:{" "}
                      <span className="value">
                        {singleOrder[0]?.inverter_type?.replace("_", " ")}
                      </span>
                    </p>
                  </Grid>
                  <Grid item lg={4}>
                    <p className="key">
                      Installation Type:{" "}
                      <span className="value">
                        {singleOrder[0]?.installation_type?.replace("_", " ")}
                      </span>
                    </p>
                  </Grid>
                  {singleOrder[0]?.is_mpu_relocation === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is Mpu:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_mpu_relocation
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_design === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is Design:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_design)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_reverse_tilt === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is reverse tilt:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_reverse_tilt
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_north_faced === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          is north faced:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_north_faced
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.two_story_home === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Two story home:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.two_story_home
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_online_monitoring === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is online monitoring:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_online_monitoring
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_trenching_dirt === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is trenching dirt:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_trenching_dirt
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_trenching_concrete === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is trenching concrete:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_trenching_concrete
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_separate_electrical_meter === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is separate electrical meter:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_separate_electrical_meter
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_electric_work_currently_on_garage ===
                    1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is electric work currently on garage:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]
                                ?.is_electric_work_currently_on_garage
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_sub_panel_on_detached_garage === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is sub panel on detached garage:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_sub_panel_on_detached_garage
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_add_on === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is add on:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_add_on)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_bird_netting === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is bird netting:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_bird_netting
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_battery === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is battery:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_battery)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_hvac === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is hvac:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_hvac)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_ducting === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is ducting:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_ducting)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_ev_charger === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is ev charger:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_ev_charger)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_meter_socket === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is meter socket:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_meter_socket
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_ev_mlo === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is ev mlo:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_ev_mlo)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_sub_panel === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is sub panel:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_sub_panel)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_de_rate === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is de rate:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_de_rate)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_stucco === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is stucco:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_stucco)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_rafter_exchange === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is rafter exchange:{" "}
                          <span className="value">
                            {Boolean(
                              singleOrder[0]?.is_rafter_exchange
                            )?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_steep === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is steep:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_steep)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_sky_light === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is sky light:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_sky_light)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                  {singleOrder[0]?.is_sky_light === 1 && (
                    <>
                      <Grid item lg={4}>
                        <p className="key">
                          Is sky light:{" "}
                          <span className="value">
                            {Boolean(singleOrder[0]?.is_sky_light)?.toString()}
                          </span>
                        </p>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Typography> */}
              {WorOrderJob ? (
                <>
                  {" "}
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    align="center"
                    sx={{ mt: 2 }}
                  >
                    <hr></hr>
                    Work Order Details
                    <hr></hr>
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                    align="center"
                  >
                    <Grid
                      container
                      columns={12}
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="stretch"
                    >
                      {WorOrderJob?.contract_path ? (
                        <Grid item lg={4}>
                          <p className="key">
                            <span className="value">
                              <PdfButton
                                target="_blank"
                                onClick={() => {
                                  window.open(
                                    `https://pmb.boxbyld.tech/profiles${WorOrderJob?.contract_path}`
                                  );
                                }}
                              >
                                <PictureAsPdfIcon></PictureAsPdfIcon>
                                {/* <Value> open Pdf</Value> */}
                              </PdfButton>{" "}
                            </span>
                          </p>
                        </Grid>
                      ) : (
                        <Grid item lg={4}>
                          No pdf
                        </Grid>
                      )}

                      <Grid item lg={4}>
                        <p className="key">
                          Created Date:{" "}
                          <span className="value">
                            {new Date(
                              WorOrderJob?.created_at
                            ).toLocaleDateString()}
                          </span>
                        </p>
                      </Grid>
                      {/* <Grid item lg={4}>
                <p className="key">
                  Installer email:{" "}
                  <span className="value">{WorOrderJob?.installer_email}</span>
                </p>
              </Grid> */}
                      {/* <Grid item lg={4}>
                <p className="key">
                  Installer name:{" "}
                  <span className="value">{WorOrderJob?.installer_name}</span>
                </p>
              </Grid> */}
                      <Grid item lg={4}>
                        <p className="key">
                          Total labor:{" "}
                          <span className="value">
                            {WorOrderJob?.labor_total}
                          </span>
                        </p>
                      </Grid>

                      <Grid item lg={4}>
                        <p className="key">
                          Work description:{" "}
                          <span className="value">
                            {WorOrderJob?.work_description}
                          </span>
                        </p>
                      </Grid>

                      <Grid item lg={4}>
                        <p className="key">
                          additional comments:{" "}
                          <span className="value">
                            {WorOrderJob?.additional_comments}
                          </span>
                        </p>
                      </Grid>
                      {WorOrderJob?.work_order_labors?.length > 0 && (
                        <>
                          <Grid item xs={12}>
                            <h3>Labor Info</h3>
                            <MyTable>
                              <tr>
                                <th>number</th>
                                <th>Description</th>
                                <th>qty</th>
                                <th>Price</th>
                                <th>Total</th>
                              </tr>
                              {WorOrderJob?.work_order_labors?.map((e, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{++i}</td>
                                    <td>{e.description}</td>
                                    <td>{e.qty}</td>
                                    <td> $ {e.price}</td>
                                    <td> $ {e.total}</td>
                                  </tr>
                                );
                              })}
                            </MyTable>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Typography>
                </>
              ) : (
                <p style={{ textAlign: "center", fontSize: "3rem" }}>No Data</p>
              )}
            </Box>
          </Modal>
        </>
      )}
      {loader === "loading" && (
        <>
          <Lottie loop={true} animationData={loaderlottie} />
        </>
      )}
    </BigSection>
  );
};

export default JobsOrders;
