import { Button, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetProfileData } from "../../Redux/Slices/ProfileSlice";
import { Bigsection } from "./DocsStyle";
import DownloadIcon from "@mui/icons-material/Download";
const Docs = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    let ignore = false;
    if (!ignore) dispatch(GetProfileData());
    return () => {
      ignore = true;
    };
  }, []);
  const userData = useSelector((state) => state.Profile.userData);
  console.log("User Data", userData);

  return (
    <Bigsection>
      <Grid container columns={12}>
        <Grid item xs={12}></Grid>
        <table id="customers">
          <tr>
            <th>Agreement path</th>
            <th>Apcr</th>
            <th>Nda</th>
          </tr>
          <tr>
            <td>
              {userData?.file?.agreement_path ? (
                <>
                  {" "}
                  <a target="_blank" href={userData?.file?.agreement_path}>
                    <Button endIcon={<DownloadIcon></DownloadIcon>}>
                      Download
                    </Button>
                  </a>{" "}
                </>
              ) : (
                <>Not found</>
              )}
            </td>
            <td>
              {userData?.file?.apcr_path ? (
                <>
                  {" "}
                  <a target="_blank" href={userData?.file?.apcr_path}>
                    <Button endIcon={<DownloadIcon></DownloadIcon>}>
                      Download
                    </Button>
                  </a>{" "}
                </>
              ) : (
                <>not found</>
              )}
            </td>
            <td>
              {userData?.file?.nda_path ? (
                <>
                  {" "}
                  <a target="_blank" href={userData?.file?.nda_path}>
                    <Button endIcon={<DownloadIcon></DownloadIcon>}>
                      Download
                    </Button>
                  </a>{" "}
                </>
              ) : (
                <>not found</>
              )}
            </td>
          </tr>
        </table>
      </Grid>
    </Bigsection>
  );
};

export default Docs;
