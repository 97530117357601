import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { states } from "../../Api";
import { toast } from "react-toastify";


export const GetStates = createAsyncThunk(
  "States/get",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${states}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("licenses has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const States = createSlice({
  name: "States",
  initialState: {
    states: "",
    status: "",
  },
  reducers: {},
  extraReducers: {
    [GetStates.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetStates.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.states = payload.data;
    },
    [GetStates.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});
export default States.reducer;
