import styled from "styled-components";

export const BigSection = styled.div`
  a {
    color: black;
    text-decoration: none;
    p {
      font-weight: 700;
      font-size: 1.1rem;
    }
  }
  a.active {
    background-color: #f9f5ff;
    color: #6941c6;
  }
`;
