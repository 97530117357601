import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  order_status,
  orederInstallerAll,
  orederInstallerApproved,
  orederInstallerPending,
  single_order,
  states,
} from "../../Api";
import { toast } from "react-toastify";

try {
  var user = JSON.parse(localStorage.getItem("installer"));
} catch (e) {}
const headers = {
  Accept: "application/json",
  Authorization: `Bearer ${user?.token}`,
};

export const GetPendingOrders = createAsyncThunk(
  "pendingOrders/get",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${orederInstallerPending}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("licenses has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GetApprovedOrders = createAsyncThunk(
  "approvedOrders/get",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${orederInstallerApproved}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("licenses has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GetAllOrders = createAsyncThunk(
  "approvedOrders/get",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${orederInstallerAll}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("licenses has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GetSingleOrder = createAsyncThunk(
  "singleOrder/get",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${single_order}/${data}`,
        headers: headers,
        data: data,
      }).then((res) => {
        // toast.success("licenses has been Added", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const ChangeOrderStatus = createAsyncThunk(
  "ChangeOrder/post",
  async (data, thunkAPI) => {
    console.log("What is in Axios", data);
    try {
      const user = JSON.parse(localStorage.getItem("installer"));
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${order_status}/${data.id}`,
        headers: headers,
        data: data.data,
      }).then((res) => {
        toast.success("Status has been Updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const Orders = createSlice({
  name: "States",
  initialState: {
    pendingOrders: "",
    status: "",
    approvedOrders: "",
    allOrders: "",
    singleOrder: "",
  },
  reducers: {},
  extraReducers: {
    [GetPendingOrders.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetPendingOrders.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.pendingOrders = payload.data;
    },
    [GetPendingOrders.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [GetApprovedOrders.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetApprovedOrders.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.approvedOrders = payload.data;
    },
    [GetApprovedOrders.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [GetAllOrders.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetAllOrders.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.allOrders = payload.data;
    },
    [GetAllOrders.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
    [GetSingleOrder.pending]: (state, { payload }) => {
      state.status = "loading";
    },
    [GetSingleOrder.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.singleOrder = payload.data;
    },
    [GetSingleOrder.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
      state.singleOrder = "";
    },
  },
});
export default Orders.reducer;
