import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Div } from "../Form/LeftSideStyle";
import EditIcon from "@mui/icons-material/Edit";
import EditAdder from "../Form/EditAdder";
import {
  adderReset,
  GetAdders,
  GetInstallerAddedAdders,
} from "../../Redux/Slices/Adders";

export const Style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    // height: "100%",
    display: "block",
  };
const MyAdders = () => {
  const addedAdders = useSelector((state) => state.Adders?.addedAdders) || [];
  const [editAdder, setEditAdder] = React.useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    let api = async () => {
      await dispatch(GetAdders());
      await dispatch(GetInstallerAddedAdders());
      await dispatch(adderReset());
    };
    api();
  }, []);

  let AddedaddersArr = [];
  addedAdders.map((e) => {
    return (AddedaddersArr = e.adders);
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      {/* Right Side */}
      <Grid item xs={3}>
        <h3 style={{ textAlign: "center" }}>Your Adders</h3>
        {AddedaddersArr.map((e, i) => {
          return (
            <Div key={i}>
              <p>
                ${e.pivot.price} <span>{e.name}</span>
              </p>
              <Button
                className="btn"
                endIcon={<EditIcon></EditIcon>}
                onClick={() => {
                  setEditAdder(e);
                  handleOpen();
                }}
              ></Button>
            </Div>
          );
        })}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Edit Adder : {editAdder?.name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <EditAdder
              closeModal={handleClose}
              singleAdder={editAdder}
            ></EditAdder>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default MyAdders;
