import { Button } from "@mui/material";
import styled from "styled-components";
import background from "../../assets/panelground.png";
export const BigSection = styled.div`
  /* width: 100%; */
  height: 100vh !important;

  .leftside {
    background-image: url(${background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    .content {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      p {
        padding: 0.5rem 0;
        font-size: 15px;
      }
    }
  }
  .rightside {
    /* height: 100vh; */
  }
  .header {
    position: relative;
    font-family: "Inter";
    &:after {
      position: absolute;
      height: 1px;
      width: 83.5%;
      left: 8rem;
      top: 0.9rem;
      content: "";
      background-color: black;
    }
  }
  .inputFile {
    text-align: center;
  }
`;
export const MyButton = styled(Button)`
  && {
    background-color: #7f56d9;
    width: 90%;
    /* padding: 0.2rem 0; */
    border: 0.623047px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4.98438px;
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: center; */
    /* align-items: center; */
    svg {
      margin: 0 5px;
    }
  }
`;
export const AddMore = styled(Button)`
  && {
    width: 100%;
    padding: 1rem 0;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #7f56d9;
    color: #7f56d9;
    margin-top: 0;
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  }
`;
export const Style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  height: "100%",
  display: "block",
};
export const Div = styled.div`
  background: #f9fafb;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  margin: 1rem 0;
  position: relative;
  p {
    font-size: 30px;
    line-height: 38px;
    color: #344054;
    font-weight: 600;
    padding: 1rem 1rem 1.2rem;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: #667085;
  }
  .btn {
    position: absolute;
    right: 0;
    top: 0rem;
    button {
      background: none;
      color: red;
      border: none;
      cursor: pointer;
    }
  }
`;
export const LiecnseDiv = styled(Div)`
  && {
  }
`;
export const LicenseDiv = styled.div`
  background: #f9f5ff;
  /* Primary/300 */
  border: 1px solid #d6bbfb;
  border-radius: 8px;
  padding: 1rem;
  margin: 0.5rem 0;
  position: relative;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: black;
    text-transform: capitalize;
  }
  span {
    color: #53389e;
    font-size: 15px;
  }
  .img {
    cursor: pointer;
    img {
      position: absolute;
      max-width: 4rem;
      height: 4rem;
      aspect-ratio: 16/9;
      border-radius: 8px;
      top: 0.1rem;
      right: 0.1rem;
    }
  }
  .deleteButton {
    position: absolute;
    bottom: 0;
    right: 0;
    button {
      color: red;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 1rem;
      font-size: 14px;
    }
  }
`;
export const Result = styled.div`
  box-shadow: 5px 5px 5px #a6a6a6;
  width: 100%;
`;
